import React, { Children, useEffect, useRef, useState } from 'react'
import { node, oneOfType, arrayOf, bool, string } from 'prop-types'
import classNames from 'classnames'

import Flickity from 'react-flickity-component'

import PaginationSummary from '../../Primitive/PaginationSummary'

const Carousel = ({
  children,
  overlayedPagination,
  largeButtons,
  bordered,
  arrowsColour,
  autoplay,
}) => {
  const [currentIndex, updateCurrentIndex] = useState(0)

  const flickity = useRef()

  const handleNext = () => {
    flickity.current.next()
  }

  const handlePrevious = () => {
    flickity.current.previous()
  }

  const handleUpdate = () => {
    updateCurrentIndex(flickity.current.selectedIndex)
  }

  const flickityOptions = {
    autoPlay: autoplay && 2500,
    prevNextButtons: false,
    pageDots: false,
    contain: true,
    selectedAttraction: 0.01,
    friction: 0.2,
    adaptiveheight: true,
    imagesLoaded: true,
    lazyLoad: false,
    wrapAround: true,
  }

  const totalSlides =
    (flickity &&
      flickity.current &&
      flickity.current.slides &&
      flickity.current.slides.length) ||
    Children.count(children)

  useEffect(() => {
    flickity.current.on('ready', handleUpdate)
    flickity.current.on('change', handleUpdate)

    return () => {
      flickity.current.destroy()
    }
  }, [])

  return (
    <div
      className={classNames(
        'BaseCarousel',
        'Carousel',
        bordered && 'CarouselBordered'
      )}
    >
      <Flickity
        static
        flickityRef={(c) => (flickity.current = c)}
        options={flickityOptions}
      >
        {children}
      </Flickity>
      <div
        className={classNames(
          'CarouselPaginationSummary',
          overlayedPagination && 'overlayed'
        )}
      >
        <PaginationSummary
          arrowsColour={arrowsColour}
          onNext={handleNext}
          onPrevious={handlePrevious}
          currentSlide={currentIndex + 1}
          totalSlides={totalSlides}
          showCount
          largeButtons={largeButtons}
        />
      </div>
    </div>
  )
}

Carousel.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  overlayedPagination: bool,
  arrowsColour: string,
  autoplay: bool,
  largeButtons: bool,
  bordered: bool,
}

export default Carousel
