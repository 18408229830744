import React from 'react'
import ReactDOM from 'react-dom'
import Navigation from '../../site/layout/Navigation'

const debug = require('debug')('Navigation')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.js-mobile-navigation').forEach((appNode) => {
      const props = JSON.parse(appNode.getAttribute('data-props') || '{}')
      ReactDOM.render(<Navigation {...props} />, appNode)
    })
  })
}
