import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const sizeMap = {
  small: 'VipLogo--small',
  medium: 'VipLogo--medium',
  large: 'VipLogo--large',
}

const VipLogo = ({ size = 'medium', withBorders, className }) => {
  return (
    <div
      className={classNames(
        'VipLogo',
        sizeMap[size],
        withBorders && 'VipLogo--withBorders',
        className
      )}
    >
      <span>VIP</span>
    </div>
  )
}

VipLogo.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  withBorders: PropTypes.bool,
  className: PropTypes.string,
}

export default VipLogo
