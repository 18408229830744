import React from 'react'
import ReactDOM from 'react-dom'
import SearchSorter from '../../../site/layout/component/Primitive/SearchResults/SearchSorter'

export default () => {
  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.js-search-sorter').forEach((appNode) => {
      const props = JSON.parse(appNode.dataset.props)
      ReactDOM.render(<SearchSorter {...props} />, appNode)
    })
  })
}
