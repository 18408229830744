import React, { useState } from 'react'
import { bool, string } from 'prop-types'
import classNames from 'classnames'

import Icon from '../Icon'
import Prose from '../Prose'
import ShrinkWrap from '../ShrinkWrap'
import Type from '../Type'

const Faq = ({ question, answer, id, initialExpanded }) => {
  const [expanded, toggleExpanded] = useState(initialExpanded)

  const handleToggleExpanded = () => {
    toggleExpanded(!expanded)
  }

  return (
    <li key={id} className={classNames('Faq', expanded && 'expanded')}>
      <button className="FaqQuestion" onClick={handleToggleExpanded}>
        <ShrinkWrap fullWidth vAlign="middle">
          <ShrinkWrap.Item>
            <Type as="h3" size="Heading3" className="FaqQuestionText">
              {question}
            </Type>
          </ShrinkWrap.Item>
          <ShrinkWrap.Item shrink>
            <Icon type="ChevronDown" width={22} className="FaqIndicator" />
          </ShrinkWrap.Item>
        </ShrinkWrap>
      </button>
      {expanded && (
        <div className="FaqAnswer">
          <div className="FaqAnswerInner">
            <Prose dangerousHtml={answer} />
          </div>
        </div>
      )}
    </li>
  )
}

Faq.propTypes = {
  question: string.isRequired,
  answer: string.isRequired,
  id: string,
  initialExpanded: bool,
}

export default Faq
