import React, { useState } from 'react'
import PropTypes from 'prop-types'
import GSButton from '../../GSButton'
import GSFilterSearch from '../GSFilterSearch'
import GSFloatingPanel from '../GSFloatingPanel'

function GSFilterToggleList(props) {
  const { label, options, onOptionChange, onClear, onSubmit, searchEnabled } =
    props

  const [searchValue, setSearchValue] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchValue.toLowerCase())
  )

  const hasActiveFilters = options.some((option) => option.checked)

  if (!options) return null
  return (
    <GSFloatingPanel
      label={label}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      isActive={hasActiveFilters}
    >
      {isOpen && (
        <div
          className="GSFilterToggleListPanel"
          role="dialog"
          aria-hidden={isOpen ? 'true' : 'false'}
        >
          <div className="GSFilterToggleListPanelInner">
            {searchEnabled && (
              <GSFilterSearch value={searchValue} setValue={setSearchValue} />
            )}
            <div className="GSFilterToggleListOptions">
              {filteredOptions.map((option) => (
                <GSButton
                  key={option.label}
                  className="GSFilterToggleListItem"
                  value={option.value}
                  variant={option.checked ? 'primary' : 'secondary'}
                  onClick={() => onOptionChange(option)}
                  size="sm"
                >
                  {option.label}
                </GSButton>
              ))}
            </div>
          </div>
          <div className="GSFilterToggleListPanelFooter">
            <button onClick={onClear} className="GSFilterToggleListClear">
              Clear All
            </button>
            <GSButton
              size="sm"
              onClick={() => {
                onSubmit()
                setIsOpen(false)
              }}
            >
              See results
            </GSButton>
          </div>
        </div>
      )}
    </GSFloatingPanel>
  )
}

GSFilterToggleList.propTypes = {
  onSubmit: PropTypes.func,
  onClear: PropTypes.func,
  label: PropTypes.string,
  searchEnabled: PropTypes.bool,
  onOptionChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      checked: PropTypes.bool,
    })
  ),
}

export default GSFilterToggleList
