import React from 'react'

const FieldRequired = () => {
  return (
    <abbr className="FieldRequired" title="This field is required">
      *
    </abbr>
  )
}

export default FieldRequired
