async function getRedemptionCode(params) {
  const { membershipId, ratePlanId } = params
  const restaurants = await fetch('/api/gourmet-society/get-redemption-code', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      membershipId,
      ratePlanId,
    }),
  })
  const data = await restaurants.json()
  return data
}

export default getRedemptionCode
