import moment from 'moment-timezone'

/*
  Please note - "All Digital Access plus Weekend Newspaers" - found in the "extra" array is misspelt purposely.
  This typo comes from the Telegraph backend systems and they are unable to change it, thus we need to handle it on our side.
*/

const requiresOneMonthAccess = [
  'All Digital Access',
  '7 Day Print',
  'Complete 6 Day',
  'Complete 5 Day',
  'All Digital Access plus Weekend Newspapers',
  'All Digital Access plus Weekend Newspaers', // do not remove, misspelt purposely!
  '2 Day Print Classic',
  'Saturday Print',
  'Saturday Print Classic',
]
const requires12MonthAccess = [
  'Standard',
  'Events Standard',
  'Digital US',
  'Digital 2024',
  'Digital and Puzzles 3rd Party',
]

export const noVipAccessProducts = [
  'Third Party Premium',
  'Digital Light',
  'Puzzles',
  'Student',
  'App-only',
]

export const subscriptionToRequiredTenureMap = {
  Standard: 12,
  'Events Standard': 12,
  'Digital US': 12,
  'All Digital Access': 1,
  '7 Day Print': 1,
  'Complete 6 Day': 1,
  'Complete 5 Day': 1,
  'All Digital Access plus Weekend Newspapers': 1,
  '2 Day Print Classic': 1,
  'Saturday Print': 1,
  'Saturday Print Classic': 1,
  'Digital 2024': 12,
  'Digital and Puzzles 3rd Party': 12,
}

const vipAccessDeterminer = (
  subscriptionType,
  continuousSubscriptionDate,
  fastTrackExtra
) => {
  if (!Array.isArray(subscriptionType)) {
    subscriptionType = [subscriptionType]
  }

  if (!continuousSubscriptionDate) return false
  if (noVipAccessProducts.includes(subscriptionType)) return false

  /*
    if user's sub is NOT in the "no vip access" list AND it has "fast-track" enabled,
    it should give the user the VIP access regardless of the subscription tenure
  */
  if (fastTrackExtra) return true

  const subscriptionDurationInMonths = moment().diff(
    moment(continuousSubscriptionDate),
    'months'
  )

  const hasOneMonthSubscriptionType = subscriptionType.some((e) =>
    requiresOneMonthAccess.includes(e)
  )
  const has12MonthSubscriptionType = subscriptionType.some((e) =>
    requires12MonthAccess.includes(e)
  )

  if (hasOneMonthSubscriptionType) {
    return subscriptionDurationInMonths >= 1
  }

  if (has12MonthSubscriptionType) {
    return subscriptionDurationInMonths >= 12
  }

  return false
}

export default vipAccessDeterminer
