import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../../Primitive/Icon'
import versionPath from '../../../../../../site/lib/version-path'

function GSSearchSuggestion(props) {
  const { suggestion, onClick } = props

  const displayImage = suggestion.image || suggestion.type === 'location'

  return (
    <button
      key={suggestion.value}
      onClick={() => onClick(suggestion)}
      className="GSSearchSuggestion"
    >
      {displayImage ? (
        <div className="GSSearchSuggestionIcon">
          {suggestion.type === 'location' ? (
            <Icon type="MapPinOutline" width={20} height={20} />
          ) : (
            <img src={suggestion.image} alt={suggestion.name} />
          )}
        </div>
      ) : (
        <div className="GSSearchSuggestionIcon">
          <img
            src={versionPath('/assets/img/content/gs-placeholder--square.png')}
            alt={suggestion.name}
            width={32}
            height={32}
            className="GSSearchSuggestionImagePlaceholder"
          />
        </div>
      )}
      <div className="GSSearchSuggestionDetails">
        <span>{suggestion.name}</span>
        {!!suggestion.location && (
          <>
            <Icon
              className="GSDot"
              a11yText="Dot"
              type="GSDot"
              width={4}
              height={4}
            />
            <span>{suggestion.location}</span>
          </>
        )}
      </div>
    </button>
  )
}

GSSearchSuggestion.propTypes = {
  onClick: PropTypes.func,
  suggestion: PropTypes.shape({
    type: PropTypes.oneOf(['location', 'restaurant', 'text']),
    image: PropTypes.string,
    name: PropTypes.string,
    location: PropTypes.string,
    value: PropTypes.string,
  }),
}

export default GSSearchSuggestion
