import React, { useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import IconButton from '../IconButton'
import Type from '../Type'

const ImageCredits = ({ credits }) => {
  const [displayCredits, toggleDisplayCredits] = useState(false)

  const handleToggleDisplayCredits = () => {
    toggleDisplayCredits(!displayCredits)
  }

  return (
    <div
      className={classNames(
        'InlineImageCreditsWrapper',
        displayCredits && 'InlineImageCreditsWrapperDisplay'
      )}
    >
      <IconButton
        a11yText="Show Credits"
        className={classNames(
          'InlineImageCreditsIcon',
          displayCredits && 'InlineImageCreditsIconActive'
        )}
        icon="Info"
        iconWidth={20}
        iconHeight={20}
        onClick={handleToggleDisplayCredits}
      />
      <Type size="ImageCredits" className="InlineImageCredits">
        CREDIT: {credits}
      </Type>
    </div>
  )
}

ImageCredits.propTypes = {
  credits: PropTypes.string,
  displayCredits: PropTypes.bool,
}

export default ImageCredits
