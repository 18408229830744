import React from 'react'
import PropTypes from 'prop-types'

import Type from '../../../Primitive/Type'
import { subscriptionToRequiredTenureMap } from '../../../../../identity/lib/vip-access-determiner'

const VipUpgradeUpsell = ({
  subscriptionTenure,
  isLoggedIn,
  isSubscriber,
  isRegistrant,
  subscriptionType,
  canUpgrade,
}) => {
  const requiredTenure =
    subscriptionToRequiredTenureMap[subscriptionType] || null
  const overOneMonth = requiredTenure === 12 && subscriptionTenure >= 1
  const lessThanOneMonth = requiredTenure === 1 && subscriptionTenure < 1

  return (
    <div className="VipUpgradeUpsell">
      <Type
        className="VipUpgradeUpsellTitle"
        as="p"
        size="VipUpgradeUpsellTitle"
      >
        {(!isLoggedIn || !isSubscriber || isRegistrant) && (
          <span>Subscribe now to enjoy Telegraph Extra</span>
        )}
        {isLoggedIn && isSubscriber && !isRegistrant && (
          <span>You don&apos;t have access to VIP rewards</span>
        )}
      </Type>
      <Type
        className="VipUpgradeUpsellDescription"
        as="p"
        size="VipUpgradeUpsellDescription"
      >
        {isLoggedIn && isSubscriber && !isRegistrant && (
          <>
            {(lessThanOneMonth || requiredTenure === 1) && (
              <>
                <div>
                  You&apos;ll automatically be granted access to VIP rewards one
                  month after your subscription start date.
                </div>
              </>
            )}
            {(overOneMonth || requiredTenure === 12) && (
              <>
                <div>
                  You&apos;ll automatically be granted access to VIP rewards 12
                  months after your subscription start date.
                </div>
                <br />
                {canUpgrade ? (
                  <div>
                    Alternatively, upgrade now and you&apos;ll unlock instant
                    access to VIP rewards like this one.
                  </div>
                ) : (
                  <div>
                    Alternatively, after one month, you can upgrade to unlock
                    instant access to VIP rewards like this one.
                  </div>
                )}
              </>
            )}
          </>
        )}
        {(!isLoggedIn || !isSubscriber || isRegistrant) && (
          <>
            <div>
              Subscribe to enjoy hand-picked rewards from Telegraph Extra, as
              well as our award-winning journalism.
            </div>
            <br />
            <div>
              Plus, after a year as a Digital Subscriber or one month as a
              Digital Plus or Print Subscriber, you will get access to VIP
              rewards like this one.
            </div>
          </>
        )}
      </Type>
    </div>
  )
}

VipUpgradeUpsell.propTypes = {
  subscriptionTenure: PropTypes.number,
  hasExtraAccess: PropTypes.bool,
  isSubscriber: PropTypes.bool,
  isRegistrant: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  subscriptionType: PropTypes.string,
  canUpgrade: PropTypes.bool,
}

export default VipUpgradeUpsell
