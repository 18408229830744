import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Icon from '../component/Primitive/Icon'
import Type from '../component/Primitive/Type'

import FilterPanel from '../component/Primitive/FilterPanel'

import NavigationItem from './component/NavigationItem'
import TelegraphLink from './component/TelegraphLink'
import UserMenu from './component/UserMenu'

const Navigation = ({
  items,
  currentSection,
  currentUrl,
  user,
  searchFacets,
  chosenFacets,
}) => {
  const [currentOpenMenu, setCurrentOpenMenu] = useState(null)
  const [isDesktop, setDesktop] = useState(true)
  const [isMobileUserMenuOpen, setIsMobileUserMenuOpen] = useState(false)
  const isLoggedIn = !!(user && user.firstName && user.email)

  useEffect(() => {
    setDesktop(window && window.matchMedia('(min-width: 1024px)').matches)
  }, [])

  return (
    <>
      <div
        className={classNames(
          'Navigation',
          currentOpenMenu === 'navigation' && 'open'
        )}
      >
        <div className="NavigationFaux" />
        <div className="NavigationPrimary">
          {items &&
            items.map(({ text, url }, i) => (
              <NavigationItem
                hideBelow={i === items.length - 1 ? 'tabletLandscape' : null}
                key={i}
                url={url}
                currentSection={currentSection}
              >
                <Type size="Navigation">{text}</Type>
              </NavigationItem>
            ))}
        </div>
        <div className="NavigationUser">
          <div className="NavigationUserInner">
            <UserMenu
              isOpen={currentOpenMenu === 'user'}
              handleOpen={() => setCurrentOpenMenu('user')}
              user={user}
              hideBelow="tablet"
              isLoggedIn={isLoggedIn}
              currentUrl={currentUrl}
            />
            <NavigationItem
              bordered
              padded
              element="button"
              onClick={() => {
                currentOpenMenu === 'filter'
                  ? setCurrentOpenMenu(null)
                  : setCurrentOpenMenu('filter')
              }}
              active={currentOpenMenu === 'filter'}
            >
              <Icon type="Search" width={16} className="NavigationItemSearch" />
            </NavigationItem>
            <NavigationItem
              bordered
              padded
              element="button"
              onClick={() => {
                currentOpenMenu === 'navigation'
                  ? setCurrentOpenMenu(null)
                  : setCurrentOpenMenu('navigation')
              }}
              active={currentOpenMenu === 'navigation'}
              hideAt="tabletLandscape"
            >
              <Icon type="Hamburger" width={20} />
            </NavigationItem>
            <TelegraphLink hideInPrimaryNav />
          </div>
        </div>
      </div>
      {currentOpenMenu === 'navigation' && (
        <div className="NavigationMenu">
          <NavigationItem
            currentSection={currentSection}
            url={items[items.length - 1].url}
          >
            <Type size="Navigation">{items[items.length - 1].text}</Type>
          </NavigationItem>
          <UserMenu
            isOpen={isMobileUserMenuOpen}
            handleOpen={() => setIsMobileUserMenuOpen(true)}
            user={user}
            hideAt="tablet"
            isLoggedIn={isLoggedIn}
            currentUrl={currentUrl}
          />
          <TelegraphLink />
        </div>
      )}
      {currentOpenMenu === 'filter' && (
        <div className="NavigationFilterPanel">
          <FilterPanel
            facets={searchFacets}
            chosenFacets={chosenFacets}
            columnsOpenOnInitialRender={isDesktop}
            handleClose={() => setCurrentOpenMenu(null)}
          />
        </div>
      )}
      {currentOpenMenu && (
        <button
          className="NavigationMenuBlur"
          onClick={() => {
            setCurrentOpenMenu(null)
            setIsMobileUserMenuOpen(false)
          }}
        />
      )}
    </>
  )
}

Navigation.propTypes = {
  searchFacets: PropTypes.object,
  chosenFacets: PropTypes.object,
  user: PropTypes.object,
  currentSection: PropTypes.string,
  currentUrl: PropTypes.string,
  baseUrl: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
}

export default Navigation
