import React, { useState } from 'react'
import PropTypes from 'prop-types'

import RedemptionButton from '../../RedemptionButton'
// import ThankYouMessaging from '../../ThankYouMessaging'
// import ConfirmMaxRedemptionModal from '../../ConfirmMaxRedemptionModal'

import FormBuilder from '../../../../FormBuilder/FormBuilder'
// import AgreeToTermsCheckbox from '../../../../AgreeToTermsCheckbox'

import Modal from '../../../../Primitive/Modal'
// import Grid from '../../../../Primitive/Grid'
import formTemplate from './form'
import classNames from 'classnames'
import Type from '../../../../Primitive/Type'

const formatFormDataToObject = (formData) => {
  const data = {}
  for (const [name, value] of formData) {
    if (data[name]) {
      data[name] += `,${value}`
    } else {
      data[name] = value
    }
  }
  return data
}

const GourmetRedemptionExperience = ({
  isEligibleSubscriber,
  userHasGourmet,
  formData,
  isUmbrella,
  userHasVipAccess,
  restrictToVipOnly,
}) => {
  if (!isEligibleSubscriber) return null
  const [formAnswers, setFormAnswers] = useState(
    formData ? formData.prepopulateData : {}
  )
  const [modalOpen, setModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [formErrors, setFormErrors] = useState({})

  const onFormSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    const body = formatFormDataToObject(new FormData(e.target))
    setFormAnswers(body)
    const res = await fetch(`/api/gourmet-society/signup`, {
      headers: { 'content-type': 'application/json' },
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify(body),
    })
    const response = await res.json()
    setIsLoading(false)
    if (response.error) {
      setFormErrors(response.error)
    }
    if (response.success) {
      setModalOpen(false)
      document.body.classList.remove('has-locked-scrolling')
      window.location.reload()
    }
  }

  const onAcceptClick = () => {
    setModalOpen(true)
  }

  const onModalClose = () => {
    setModalOpen(false)
    setFormAnswers(formData.prepopulateData)
    setFormErrors({})
  }

  // TODO: when user has gourmet, show button for membership
  if (restrictToVipOnly && !userHasVipAccess) return null

  return (
    <>
      {!userHasGourmet && (
        <>
          <div className={classNames('RedemptionInstructions')}>
            <Type
              className="RedemptionInstructionsTitle"
              size="RedemptionInstructionsTitle"
            >
              Consent
            </Type>
            <div className="Type TypeRedemptionInstructionsDescription">
              To gain full access to the Gourmet Society, you must fill out a
              consent form.
            </div>
          </div>

          <RedemptionButton
            isUmbrella={isUmbrella}
            ctaText={'Consent Form'}
            onClick={onAcceptClick}
          />
          <Modal
            title={'Activate your Gourmet Society membership'}
            onClose={onModalClose}
            isOpen={modalOpen}
          >
            <FormBuilder
              questions={formTemplate.questions}
              data={formAnswers}
              onSubmit={onFormSubmit}
              isLoading={isLoading}
              errors={formErrors}
            />
          </Modal>
        </>
      )}
    </>
  )
}

GourmetRedemptionExperience.propTypes = {
  isEligibleSubscriber: PropTypes.bool,
  addToCalendarEnabled: PropTypes.bool,
  redemptionButtonText: PropTypes.string,
  termsAndConditions: PropTypes.string,
  redemptionTypeProperties: PropTypes.object,
  formData: PropTypes.object,
  userHasPreviouslyRedeemed: PropTypes.bool,
  userHasGourmet: PropTypes.bool,
  addToCalendarStartDate: PropTypes.instanceOf(Date),
  redemptionLimit: PropTypes.number,
  consequentOffers: PropTypes.any,
  headline: PropTypes.string,
  umbrellaChildrenOpenInNewPage: PropTypes.any,
  isUmbrella: PropTypes.bool,
  isUmbrellaView: PropTypes.bool,
  userHasVipAccess: PropTypes.bool,
  restrictToVipOnly: PropTypes.bool,
}

export default GourmetRedemptionExperience
