import React from 'react'
import { bool } from 'prop-types'

import Type from '../../../Primitive/Type'

const IneligibleSubscription = ({ hasExtraAccess }) => {
  return (
    <div className="SubscriptionUpsell">
      <Type
        className="SubscriptionUpsellTitle"
        as="p"
        size="SubscriptionUpsellTitle"
      >
        {hasExtraAccess
          ? `You don't have access to VIP rewards`
          : `You don't have access to Telegraph Extra`}
      </Type>
      <Type
        className="SubscriptionUpsellDescription"
        as="p"
        size="SubscriptionUpsellDescription"
      >
        <p>
          This reward can only be redeemed by subscribers who have access to
          Telegraph Extra VIP.
        </p>
        <br />
        <p>Click the button below for more information on your eligibility.</p>
      </Type>
    </div>
  )
}

IneligibleSubscription.propTypes = {
  hasExtraAccess: bool,
}

export default IneligibleSubscription
