import React from 'react'

import PropTypes from 'prop-types'

const Confirmation = ({ confirmationText }) => (
  <div className="SquadUpConfirmation">
    <h1>Confirmation</h1>
    <hr />
    <h2>Thank you for signing up!</h2>
    {confirmationText ? (
      <p dangerouslySetInnerHTML={{ __html: confirmationText }} />
    ) : (
      <>
        <p>
          A confirmation email has been sent to the email provided within the
          form.
        </p>
        <p>
          Please check your spam folder if you do not receive the confirmation
          email within 15 minutes.
        </p>
      </>
    )}
  </div>
)

Confirmation.propTypes = {
  confirmationText: PropTypes.string,
}

export default Confirmation
