function formatAvailability(availability) {
  const days = {
    monday: 'Mon',
    tuesday: 'Tue',
    wednesday: 'Wed',
    thursday: 'Thu',
    friday: 'Fri',
    saturday: 'Sat',
    sunday: 'Sun',
  }

  const availableDays = Object.keys(availability).filter(
    (day) => availability[day].day
  )

  if (availableDays.length === 0) return 'No availability'

  const formattedDays = availableDays.map((day) => days[day])

  let result = ''
  let start = formattedDays[0]
  let end = start

  for (let i = 1; i < formattedDays.length; i++) {
    const current = formattedDays[i]
    const previous = formattedDays[i - 1]

    if (daysMatchConsecutively(previous, current)) {
      end = current
    } else {
      result += start === end ? start : `${start}–${end}`
      result += ', '
      start = current
      end = current
    }
  }

  result += start === end ? start : `${start}–${end}`

  return result
}

function daysMatchConsecutively(prev, curr) {
  const order = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  return order.indexOf(curr) === order.indexOf(prev) + 1
}

export default formatAvailability
