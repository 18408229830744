import React from 'react'
import PropTypes from 'prop-types'

import Type from '../../../Primitive/Type'
import classNames from 'classnames'

const RedemptionInstructions = ({ instructions, bordered }) => (
  <div
    className={classNames(
      'RedemptionInstructions',
      bordered && 'RedemptionInstructionsBordered'
    )}
  >
    <Type
      className="RedemptionInstructionsTitle"
      size="RedemptionInstructionsTitle"
    >
      How to Claim
    </Type>
    <div
      className="Type TypeRedemptionInstructionsDescription"
      dangerouslySetInnerHTML={{
        __html: instructions,
      }}
    />
  </div>
)

RedemptionInstructions.propTypes = {
  title: PropTypes.string,
  instructions: PropTypes.string,
  bordered: PropTypes.bool,
}

export default RedemptionInstructions
