import React from 'react'
import { bool, node } from 'prop-types'
import classNames from 'classnames'

// import styles from "./PanelAlt.scss";

const PanelAlt = ({ children, corners, grow }) => (
  <div className={classNames('PanelAlt', corners && 'corners', grow && 'grow')}>
    {children}
  </div>
)

PanelAlt.propTypes = {
  children: node.isRequired,
  corners: bool,
  grow: bool,
}

export default PanelAlt
