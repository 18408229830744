import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import FeaturedGridWrapper from '../Primitive/FeaturedGridWrapper'
import Grid from '../Primitive/Grid'
import Summary from '../Primitive/Summary'
import ButtonStandard from '../Primitive/ButtonStandard'
import Icon from '../Primitive/Icon'

class WatchAgain extends PureComponent {
  constructor() {
    super()
    this.state = {
      showAll: false,
    }
  }
  render() {
    const { offers, limitCount } = this.props

    return (
      <FeaturedGridWrapper
        tabletConstrained
        alternativeBackground
        title="Watch again"
        subTitle="Browse recordings of our previous online events, featuring unmissable conversation, music and dance."
      >
        <Grid flex gutter="default">
          {!this.state.showAll &&
            offers &&
            offers.length > 0 &&
            offers.slice(0, limitCount ? 4 : 8).map((offer, i) => (
              <Grid.Item
                trailingGap="default"
                key={`offer--${i}`}
                width={{ m: 1 / 2, tl: 1 / 4 }}
              >
                <Summary offer={offer} hideStatus />
              </Grid.Item>
            ))}
          {this.state.showAll &&
            offers &&
            offers.length > 0 &&
            offers.map((offer, i) => (
              <Grid.Item
                trailingGap="default"
                key={`offer--${i}`}
                width={{ m: 1 / 2, tl: 1 / 4 }}
              >
                <Summary offer={offer} hideStatus />
              </Grid.Item>
            ))}
        </Grid>
        <div className="ShowAllButton">
          <ButtonStandard
            secondary
            onClick={() =>
              this.setState((prevState) => ({
                showAll: !prevState.showAll,
              }))
            }
          >
            <span>{`${this.state.showAll ? 'See Less' : 'See All'}`}</span>
            <Icon
              width={15}
              height={10}
              className="va-1"
              type={`${this.state.showAll ? 'ChevronUp' : 'ChevronDown'}`}
            />
          </ButtonStandard>
        </div>
      </FeaturedGridWrapper>
    )
  }
}
WatchAgain.propTypes = {
  offers: PropTypes.array,
  limitCount: PropTypes.bool,
}
export default WatchAgain
