import React from 'react'
import { node } from 'prop-types'

// // import styles from "./FieldAssistance.scss";

const FieldAssistance = ({ children }) => (
  <div className="FieldAssistance">{children}</div>
)

FieldAssistance.propTypes = {
  children: node.isRequired,
}

export default FieldAssistance
