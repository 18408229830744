const formTemplate = {
  title: 'Activate your Gourmet Society membership',
  questions: [
    {
      question: '<p>\n\tFirst name\n</p>\n',
      required: true,
      description: null,
      inputType: 'text',
      prePopulate: 'firstName',
      key: 'firstName',
      wordLimit: null,
      width: 'half',
      placeholderAsQuestion: false,
      responses: [],
    },
    {
      question: '<p>\n\tLast name\n</p>\n',
      required: true,
      description: null,
      inputType: 'text',
      prePopulate: 'lastName',
      key: 'lastName',
      wordLimit: null,
      width: 'half',
      placeholderAsQuestion: false,
      responses: [],
    },
    {
      question: '<p>\n\tEmail Address\n</p>\n',
      required: true,
      description: null,
      inputType: 'text',
      prePopulate: 'email',
      key: 'email',
      wordLimit: null,
      width: 'full',
      placeholderAsQuestion: false,
      responses: [],
    },
    {
      question: '<p>\n\tI agree to the terms and conditions&nbsp;\n</p>\n',
      required: true,
      description: null,
      inputType: 'inlineCheckbox',
      prePopulate: null,
      key: 'termsAndConditions',
      wordLimit: null,
      width: 'full',
      placeholderAsQuestion: false,
      responses: [],
    },
  ],
}

export default formTemplate
