import React, { useState, useEffect } from 'react'
import { func, string, array, object, bool } from 'prop-types'

import FilterColumn from './component/FilterColumn'

import ButtonStandard from '../ButtonStandard'
import IconButton from '../IconButton'
import Grid from '../Grid'
import PanelAlt from '../PanelAlt'
import TextAlign from '../TextAlign'
import Type from '../Type'

const titleToFacetName = {
  Type: 'category',
  Interest: 'interests',
  Location: 'generalLocation',
  'Open To': 'openTo',
}

const convertFacetToFilter = (name, facetValues, columnSpan) => {
  const filter = {
    title: name,
    items: [],
    columnSpan,
    facetName: titleToFacetName[name],
  }
  Object.keys(facetValues).forEach((facetName) => {
    filter.items.push({ label: facetName, count: facetValues[facetName] })
  })
  return filter
}

const convertFacetsToFilters = (facets) => {
  return [
    convertFacetToFilter('Type', facets.category, 1),
    convertFacetToFilter('Interest', facets.interests, 2),
    convertFacetToFilter('Location', facets.generalLocation, 1),
    convertFacetToFilter('Open To', facets.openTo, 1),
  ]
}

const FilterPanel = ({
  facets,
  chosenFacets,
  handleClose,
  columnsOpenOnInitialRender,
}) => {
  const [rawFacets, setRawFacets] = useState(facets)
  const [filters, setFilters] = useState([])
  const defaultFacets = {
    category: [],
    interests: [],
    generalLocation: [],
    openTo: [],
  }
  const [selectedFacets, setSelectedFacets] = useState(
    chosenFacets || defaultFacets
  )

  useEffect(() => {
    const filters = convertFacetsToFilters(rawFacets)
    setFilters(filters)
  }, [rawFacets])

  const getFacets = async () => {
    const res = await fetch(`/api/search-facets`, {
      headers: { 'content-type': 'application/json' },
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify(selectedFacets),
    })
    if (res.status == 200) {
      const result = await res.json()
      setRawFacets(result.facets)
    }
  }

  useEffect(() => {
    getFacets()
  }, [selectedFacets])

  const onFacetChange = (facetName, selectedValues) => {
    const updatedFacets = { ...selectedFacets }
    updatedFacets[facetName] = selectedValues
    setSelectedFacets(updatedFacets)
  }

  return (
    <form action="/search" method="get" className="FilterPanel">
      <PanelAlt>
        <div className="FilterPanelInner">
          <TextAlign right>
            <IconButton
              type="button"
              icon="Cross"
              a11yText="Placeholder icon"
              className="FilterPanelClose"
              small
              onClick={handleClose}
            />
          </TextAlign>
          <Grid>
            <Grid gutter="default" flex>
              {filters.map((filter, index) => (
                <Grid.Item
                  width={{
                    d: (filter.columnSpan ? filter.columnSpan : 1) / 6,
                  }}
                  key={index}
                >
                  <FilterColumn
                    checkedFilters={selectedFacets[filter.facetName]}
                    onChange={onFacetChange}
                    isExpanded={columnsOpenOnInitialRender}
                    {...filter}
                  />
                </Grid.Item>
              ))}
            </Grid>
          </Grid>
          <div className="FilterPanelControls">
            <Type size="FiltersTitle">
              <ButtonStandard
                transparent
                onClick={() => {
                  setSelectedFacets(defaultFacets)
                }}
              >
                Reset
              </ButtonStandard>
            </Type>
            <ButtonStandard type="submit" secondary alternative>
              Apply
            </ButtonStandard>
          </div>
        </div>
      </PanelAlt>
    </form>
  )
}

FilterPanel.propTypes = {
  facets: object,
  chosenFacets: object,
  action: string,
  onChange: func,
  allFilters: array,
  handleClose: func,
  columnsOpenOnInitialRender: bool,
}

export default FilterPanel
