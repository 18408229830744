import React from 'react'
import classNames from 'classnames'
import { bool, node } from 'prop-types'

// import styles from "./TextAlign.scss";

const TextAlign = ({ center, children, justify, left, right }) => {
  const getAlignmentType = () => {
    if (center) return 'center'
    if (justify) return 'justify'
    if (left) return 'left'
    if (right) return 'right'
  }

  const alignmentType = getAlignmentType()

  return (
    <div className={classNames('TextAlign', alignmentType && alignmentType)}>
      {children}
    </div>
  )
}

TextAlign.propTypes = {
  children: node.isRequired,
  center: bool,
  justify: bool,
  left: bool,
  right: bool,
}

export default TextAlign
