import React from 'react'
import classNames from 'classnames'
import { bool, node, string } from 'prop-types'

import SmartLink from '../SmartLink'

const ButtonBase = ({ block, className, disabled, ...other }) => (
  <SmartLink
    className={classNames(
      'ButtonBase',
      block && 'block',
      disabled && 'disabled',
      className
    )}
    disabled={disabled}
    {...other}
  ></SmartLink>
)

ButtonBase.propTypes = {
  block: bool,
  children: node.isRequired,
  className: string,
  disabled: bool,
}

export default ButtonBase
