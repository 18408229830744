import React from 'react'
import classNames from 'classnames'
import { bool, node, string } from 'prop-types'

// import styles from "./ShrinkWrapWrapper.scss";

import Element from '../../../Element'

const ShrinkWrapWrapper = ({ children, as, fixed, fullWidth, mobileStack }) => (
  <Element
    as={as}
    className={classNames(
      'ShrinkWrapWrapper',
      fixed && 'fixed',
      fullWidth && 'fullWidth',
      mobileStack && 'mobileStack'
    )}
  >
    {children}
  </Element>
)

ShrinkWrapWrapper.propTypes = {
  children: node.isRequired,
  as: string,
  fixed: bool,
  fullWidth: bool,
  mobileStack: bool,
}

export default ShrinkWrapWrapper
