import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Container from '../../../../layout/component/Primitive/Container'
import UmbrellaChildOffer from '../../../../layout/component/UmbrellaChildOffer'
import UmbrellaOffersRedemptionContextProvider from '../../../../layout/component/Context/UmbrellaOffersRedemptionContext'

export const ChildOffersAreaContainer = (props) => {
  const { offers, closedTitle, umbrellaChildrenOpenInNewPage } = props
  const closedStates = ['Closed', 'Sold out']
  const open = offers.filter((offer) => !closedStates.includes(offer.status))
  const closed = offers.filter((offer) => closedStates.includes(offer.status))

  const renderChildren = (offer, i) => {
    const nextTwoOffers = (offers || [])
      .map((offer) => ({
        _id: offer && offer._id,
        headline: offer && offer.headline,
      }))
      .filter((offer) => offer && offer.headline)

    return (
      <UmbrellaChildOffer
        key={i}
        {...offer}
        isUmbrella={true}
        consequentOffers={nextTwoOffers || []}
        openChildOffer={umbrellaChildrenOpenInNewPage}
      />
    )
  }

  return (
    <UmbrellaOffersRedemptionContextProvider>
      <Container as="div" center className="UmbrellaChildOffersContainer">
        {open.map(renderChildren)}
        {closed.length > 0 && (
          <Fragment>
            <h2>{closedTitle}</h2>
            {closed.map(renderChildren)}
          </Fragment>
        )}
      </Container>
    </UmbrellaOffersRedemptionContextProvider>
  )
}

ChildOffersAreaContainer.propTypes = {
  offers: PropTypes.array,
  closedTitle: PropTypes.string,
  umbrellaChildrenOpenInNewPage: PropTypes.bool,
}
