import React from 'react'
import PropTypes from 'prop-types'
import GSFiltersMobilePanel from './GSFiltersMobilePanel'
import GSFilterSearch from '../GSFilterSearch'
import GSButton from '../../GSButton'

export function GSFiltersMobileFilterView(props) {
  const {
    label,
    submitLabel,
    onBack,
    focusRef,
    onClear,
    onSubmit,
    searchEnabled,
    options,
    primaryOptions,
    onOpenChange,
    onOptionChange,
    onPrimaryOptionChange,
    filterViewOpen,
  } = props

  const [searchValue, setSearchValue] = React.useState('')

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchValue.toLowerCase())
  )

  return (
    <GSFiltersMobilePanel
      label={label}
      submitLabel={submitLabel}
      focusRef={focusRef}
      onOpenChange={onOpenChange}
      onSubmit={onSubmit}
      filterViewOpen={filterViewOpen}
      {...(onBack && { onBack })}
      {...(onClear && { onClear })}
    >
      <div className="GSFiltersMobileFilterView">
        {searchEnabled && (
          <GSFilterSearch value={searchValue} setValue={setSearchValue} />
        )}
        <div className="GSFiltersMobileFilterViewFilters">
          {primaryOptions &&
            primaryOptions.length > 0 &&
            primaryOptions.map((option) => (
              <GSButton
                key={option.label}
                className="GSFiltersMobileFilterViewToggle"
                value={option.value}
                variant={option.checked ? 'primary' : 'outline'}
                onClick={() => onPrimaryOptionChange(option)}
                size="sm"
              >
                {option.label}
              </GSButton>
            ))}
          {filteredOptions.map((option) => (
            <GSButton
              key={option.label}
              className="GSFiltersMobileFilterViewToggle"
              value={option.value}
              variant={option.checked ? 'primary' : 'outline'}
              onClick={() => onOptionChange(option)}
              size="sm"
            >
              {option.label}
            </GSButton>
          ))}
        </div>
      </div>
    </GSFiltersMobilePanel>
  )
}

GSFiltersMobileFilterView.propTypes = {
  label: PropTypes.string,
  onBack: PropTypes.func,
  submitLabel: PropTypes.string,
  searchEnabled: PropTypes.bool,
  filterViewOpen: PropTypes.bool,
  onPrimaryOptionChange: PropTypes.func,
  focusRef: PropTypes.object,
  onClear: PropTypes.func,
  onOpenChange: PropTypes.func,
  onSubmit: PropTypes.func,
  primaryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      checked: PropTypes.bool,
    })
  ),
  onOptionChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      checked: PropTypes.bool,
    })
  ),
}
