import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import ButtonStandard from '../ButtonStandard'
import Faq from '../Faq'
import Icon from '../Icon'
import List from '../List'
import TextAlign from '../TextAlign'

class FaqList extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { expanded: false }
  }

  render() {
    let questions = this.props.questions

    if (!this.state.expanded) {
      questions = this.props.questions.slice(0, 4)
    }

    return (
      <>
        <List className="FaqList">
          {questions.map(({ question, answer }, i) => (
            <Faq key={`Faq--${i}`} question={question} answer={answer} />
          ))}
        </List>
        {this.props.questions.length > 4 && (
          <TextAlign center>
            <ButtonStandard
              secondary
              dropdown
              className="FaqListButton"
              onClick={() =>
                this.setState((prevState) => ({
                  expanded: !prevState.expanded,
                }))
              }
            >
              <span>{`${this.state.expanded ? 'Show Less' : 'Show All'}`}</span>
              <Icon
                width={15}
                height={8}
                type={`${this.state.expanded ? 'ChevronUp' : 'ChevronDown'}`}
              />
            </ButtonStandard>
          </TextAlign>
        )}
      </>
    )
  }
}

FaqList.propTypes = {
  questions: PropTypes.array,
}

export default FaqList
