import React from 'react'
import PropTypes from 'prop-types'

import ButtonStandard from '../../../Primitive/ButtonStandard'

const VipUpgradeButton = ({
  isSubscriber,
  isRegistrant,
  currentUrl,
  canUpgrade,
}) => {
  const subscribeLink = () => {
    const redirectTo = encodeURIComponent(currentUrl + '?refresh=1')

    if (isSubscriber && !isRegistrant) {
      if (canUpgrade) {
        return `https://secure.telegraph.co.uk/customer/secure/upgrades?redirectTo=${redirectTo}`
      } else {
        return '/faqs'
      }
    }

    return `https://www.telegraph.co.uk/customer/subscribe?ICID=subs-offers_rewards_onsite-asset_generic_telegraphextra&redirectTo=${redirectTo}`
  }

  const ButtonText = () => {
    if (isSubscriber && !isRegistrant) {
      if (canUpgrade) return 'Upgrade now'
      return 'FAQs'
    }
    return 'Find out more'
  }

  return (
    <div className="VipUpgradeButtonWrapper">
      <ButtonStandard
        className="VipUpgradeButton"
        clipped
        href={subscribeLink()}
      >
        <ButtonText />
      </ButtonStandard>
    </div>
  )
}

VipUpgradeButton.propTypes = {
  currentUrl: PropTypes.string,
  isSubscriber: PropTypes.bool,
  isRegistrant: PropTypes.bool,
  canUpgrade: PropTypes.bool,
}

export default VipUpgradeButton
