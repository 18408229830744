import React from 'react'
import PropTypes from 'prop-types'

import ButtonStandard from '../../../Primitive/ButtonStandard'

const SubscribeButton = ({
  ctaText,
  currentUrl,
  hasExtraAccess,
  isSubscriber,
  isUpgradable,
}) => {
  const subscribeLink = () => {
    const redirectTo = encodeURIComponent(currentUrl + '?refresh=1')

    if (isSubscriber && !hasExtraAccess) {
      if (isUpgradable) {
        return `https://secure.telegraph.co.uk/customer/secure/upgrades?redirectTo=${redirectTo}`
      } else {
        return '/faqs'
      }
    }

    return `https://www.telegraph.co.uk/customer/subscribe?ICID=subs-offers_rewards_onsite-asset_generic_telegraphextra&redirectTo=${redirectTo}`
  }

  const ButtonText = () => {
    if (isSubscriber && !hasExtraAccess) {
      if (isUpgradable) {
        return 'Upgrade now'
      } else {
        return 'FAQs'
      }
    }
    return ctaText || 'Find out more'
  }
  return (
    <div className="SubscribeButtonWrapper">
      <ButtonStandard
        className="SubscribeButton"
        clipped
        href={subscribeLink()}
      >
        <ButtonText />
      </ButtonStandard>
    </div>
  )
}

SubscribeButton.propTypes = {
  ctaText: PropTypes.string,
  currentUrl: PropTypes.string,
  hasExtraAccess: PropTypes.bool,
  isSubscriber: PropTypes.bool,
  isUpgradable: PropTypes.bool,
}

export default SubscribeButton
