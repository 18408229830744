import React, { useState } from 'react'
import PropTypes from 'prop-types'
import GSButton from '../../GSButton'
import GSCheckbox from '../GSCheckbox'
import GSFilterSearch from '../GSFilterSearch'
import GSFloatingPanel from '../GSFloatingPanel'

function GSFilterChecklist(props) {
  const {
    label,
    primaryOptions,
    onPrimaryOptionChange,
    options,
    onOptionChange,
    onClear,
    onSubmit,
    searchEnabled,
  } = props

  const [searchValue, setSearchValue] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchValue.toLowerCase())
  )

  const hasActiveFilters =
    options.some((option) => option.checked) ||
    (primaryOptions && primaryOptions.some((option) => option.checked))

  if (!options) return null

  return (
    <GSFloatingPanel
      label={label}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      isActive={hasActiveFilters}
    >
      <div className="GSFilterChecklistPanelInner">
        {searchEnabled && (
          <GSFilterSearch value={searchValue} setValue={setSearchValue} />
        )}

        {!!primaryOptions && primaryOptions.length > 0 && (
          <div className="GSFilterChecklistPrimaryOptions">
            {primaryOptions.map((option) => (
              <GSCheckbox
                key={option.label}
                className="GSFilterChecklistItem"
                id={option.label}
                name={option.label}
                label={option.label}
                value={option.value}
                checked={option.checked}
                onChange={() => onPrimaryOptionChange(option)}
              />
            ))}
          </div>
        )}
        <div className="GSFilterChecklistOptions">
          {filteredOptions.map((option) => (
            <GSCheckbox
              key={option.label}
              className="GSFilterChecklistItem"
              id={option.label}
              name={option.label}
              label={option.label}
              value={option.value}
              checked={option.checked}
              onChange={() => onOptionChange(option)}
            />
          ))}
        </div>
      </div>
      <div className="GSFilterChecklistPanelFooter">
        <button onClick={onClear} className="GSFilterChecklistClear">
          Clear All
        </button>
        <GSButton
          size="sm"
          onClick={() => {
            onSubmit()
            setIsOpen(false)
          }}
        >
          See results
        </GSButton>
      </div>
    </GSFloatingPanel>
  )
}

GSFilterChecklist.propTypes = {
  onSubmit: PropTypes.func,
  onClear: PropTypes.func,
  label: PropTypes.string,
  searchEnabled: PropTypes.bool,
  onPrimaryOptionChange: PropTypes.func,
  primaryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      checked: PropTypes.bool,
    })
  ),
  onOptionChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      checked: PropTypes.bool,
    })
  ),
}

export default GSFilterChecklist
