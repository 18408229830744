import React from 'react'
import { arrayOf, bool, node, number, shape, string } from 'prop-types'

import CaptionedMedia from '../CaptionedMedia'
import ResponsiveImage from '../ResponsiveImage'
import ImageCredits from '../ImageCredits'

const InlineImage = ({
  alt,
  caption,
  height,
  safeAreaRight,
  sizes,
  src,
  srcSet,
  width,
  credits,
  link,
  isExternalLink,
}) => {
  const ContainerEl = link ? 'a' : 'div'
  return (
    <CaptionedMedia caption={caption} safeAreaRight={safeAreaRight}>
      <ContainerEl
        className="InlineImage"
        href={link || undefined}
        target={link && isExternalLink ? '_blank' : undefined}
      >
        <ResponsiveImage
          width={width}
          height={height}
          alt={alt}
          src={src}
          srcSet={srcSet}
          sizes={sizes}
        />
        {credits && <ImageCredits credits={credits} />}
      </ContainerEl>
    </CaptionedMedia>
  )
}

InlineImage.propTypes = {
  link: string,
  isExternalLink: bool,
  alt: string,
  caption: node,
  height: number,
  safeAreaRight: string,
  sizes: arrayOf(string),
  src: string,
  srcSet: arrayOf(shape({ width: number.isRequired, src: string.isRequired })),
  width: number,
  credits: string,
}

export default InlineImage
