import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Carousel from '../Primitive/Carousel'
import Container from '../Primitive/Container'
import ImageCredits from '../Primitive/ImageCredits'

class OfferImages extends PureComponent {
  render() {
    const {
      images,
      fullWidth,
      offerHeroCarouselArrowsColour,
      offerHeroCarouselAutoplay,
    } = this.props
    const mobileSrcs = (image) => [
      { width: 360, src: image.url['360m'] },
      { width: 480, src: image.url['480m'] },
      { width: 640, src: image.url['640m'] },
      { width: 800, src: image.url['800m'] },
    ]
    const desktopInsetSrcs = (image) => [
      { width: 800, src: image.url['800di'] },
      { width: 960, src: image.url['960di'] },
      { width: 1080, src: image.url['1080di'] },
      { width: 1280, src: image.url['1280di'] },
    ]
    const desktopFullWidthSrcs = (image) => [
      { width: 800, src: image.url['800df'] },
      { width: 960, src: image.url['960df'] },
      { width: 1080, src: image.url['1080df'] },
      { width: 1280, src: image.url['1280df'] },
      { width: 1440, src: image.url['1440df'] },
      { width: 1920, src: image.url['1920df'] },
    ]
    const generateSrcSet = (sources, image) => {
      return sources(image)
        .map((src) => `${src.src} ${src.width}w`)
        .join(', ')
    }

    const PictureWithCredits = ({ image }) => {
      return (
        <>
          {image.credits && <ImageCredits credits={image.credits} />}
          <picture>
            <source
              media="(max-width:768px)"
              srcSet={generateSrcSet(mobileSrcs, image)}
            />
            <source
              media="(min-width:769px)"
              srcSet={
                fullWidth
                  ? generateSrcSet(desktopFullWidthSrcs, image)
                  : generateSrcSet(desktopInsetSrcs, image)
              }
            />
            <img
              src={image.url['1280w']}
              alt={image.alt}
              className="OfferImagesImage"
            />
          </picture>
        </>
      )
    }

    return (
      <Container
        center
        size={fullWidth ? null : 'wide'}
        className="OfferImages"
      >
        {images.length === 1 ? (
          <PictureWithCredits image={images[0]} />
        ) : (
          <Carousel
            arrowsColour={offerHeroCarouselArrowsColour}
            autoplay={offerHeroCarouselAutoplay}
            overlayedPagination
            largeButtons
          >
            {images.map((image, i) => (
              <div key={i} style={i > 0 ? { position: 'absolute' } : null}>
                <PictureWithCredits image={image} />
              </div>
            ))}
          </Carousel>
        )}
      </Container>
    )
  }
}

OfferImages.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object),
  offerHeroCarouselArrowsColour: PropTypes.string,
  offerHeroCarouselAutoplay: PropTypes.string,
  fullWidth: PropTypes.bool,
}

export default OfferImages
