import React from 'react'
import ReactDOM from 'react-dom'
import FaqList from '../../../site/layout/component/Primitive/FaqList'

const debug = require('debug')('frequently-asked')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    debug('init FAQs')
    const wrappers = Array.from(document.querySelectorAll('.js-faqs'))

    if (wrappers.length < 1) return

    wrappers.forEach((wrapper) => {
      const props = JSON.parse(wrapper.getAttribute('data-props'))
      ReactDOM.render(<FaqList {...props} />, wrapper)
    })
  })
}
