import React from 'react'
import ReactDOM from 'react-dom'
import { ChildOffersAreaContainer } from '../../../site/widgets/offer-layout/redemption-panel/component/ChildOffersAreaContainer'

export default () => {
  const initialise = (appNode) => {
    const props = JSON.parse(appNode.dataset.props)
    ReactDOM.render(<ChildOffersAreaContainer {...props} />, appNode)
  }

  document.addEventListener('DOMContentLoaded', () => {
    document
      .querySelectorAll('.js-umbrella-offers-area-container')
      .forEach(initialise)
  })
}
