import React, { useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import Icon from '../Icon'
import IconButton from '../IconButton'
import Type from '../Type'
import TextAlign from '../TextAlign'

const Modal = ({ title, children, isOpen, onClose, onOpen, noPadding }) => {
  if (!isOpen) return null

  useEffect(() => {
    const bodyWidth = document.body.clientWidth
    document.body.classList.add('has-locked-scrolling')
    onOpen && onOpen()
    document.body.style.width = bodyWidth + 'px'
  })

  const onModalClose = () => {
    document.body.classList.remove('has-locked-scrolling')
    onClose()
  }

  return ReactDOM.createPortal(
    <div className="ModalWrapper">
      <div className="ModalOverlay" onClick={onModalClose} tabIndex={-1} />
      <div role="document">
        <div className="Modal">
          {/* This needs to be set to display only after tablet size */}
          <Icon type={'StatusArrowSmall'} className="ModalArrow" width={30} />
          <div
            className={classNames(
              'ModalInner',
              noPadding && 'ModalInnerNoPadding'
            )}
          >
            <div className="ModalClose">
              <IconButton
                onClick={onModalClose}
                icon="Close"
                iconWidth={16}
                className="ModalCloseIcon"
              >
                <Type size="ModalClose" className="ModalCloseText">
                  Close
                </Type>
              </IconButton>
            </div>
            <TextAlign center>
              <Type className="ModalTitle" size="ModalTitle">
                {title}
              </Type>
            </TextAlign>
            <div className="ModalContent">{children}</div>
          </div>
        </div>
      </div>
    </div>,
    document.querySelector('.HeaderModalContainer')
  )
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func,
  title: PropTypes.string.isRequired,
  noPadding: PropTypes.bool,
}

export default Modal
