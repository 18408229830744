import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Spinner from '../Primitive/Spinner'
import FormBuilderQuestion from './FormBuilderQuestion'
import Container from '../Primitive/Container'
import Grid from '../Primitive/Grid'
import Notification from '../Primitive/Notification'
import ButtonStandard from '../Primitive/ButtonStandard'

class FormBuilder extends PureComponent {
  render() {
    const {
      onSubmit,
      onChange,
      data = {},
      errors = {},
      isLoading,
      errorMessage,
      questions,
      hasGutter,
      children,
      submitButtonText,
    } = this.props

    const widthClasses = {
      half: { m: 1, tl: 1 / 2 },
    }

    return (
      <Container center gutter={hasGutter} className="FormBuilder">
        <form className="form" onSubmit={onSubmit}>
          <Grid gutter="default">
            {questions &&
              questions.map((question, i) => {
                return (
                  <Grid.Item
                    key={`form-builder-${i}`}
                    width={widthClasses[question.width]}
                    trailingGap="default"
                  >
                    <FormBuilderQuestion
                      question={question}
                      errors={errors}
                      data={data}
                      id={i}
                      onChange={onChange}
                    />
                  </Grid.Item>
                )
              })}
            {children}
          </Grid>
          {errors && Object.keys(errors).length > 0 && (
            <Notification status="error">
              <p>
                We were not able to process your request. Please check above and
                try again.
              </p>
              {errorMessage && <p>{errorMessage}</p>}
            </Notification>
          )}
          {isLoading ? (
            <ButtonStandard block disabled>
              <Spinner size={30} />
            </ButtonStandard>
          ) : (
            <ButtonStandard secondary block type="submit">
              {submitButtonText ? submitButtonText : 'Enter'}
            </ButtonStandard>
          )}
        </form>
      </Container>
    )
  }
}

FormBuilder.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  data: PropTypes.object,
  isLoading: PropTypes.bool,
  errorMessage: PropTypes.string,
  errors: PropTypes.object,
  questions: PropTypes.array,
  hasGutter: PropTypes.bool,
  children: PropTypes.node,
  submitButtonText: PropTypes.string,
}

export default FormBuilder
