import React, { useState } from 'react'
import PropTypes from 'prop-types'

import SmartLink from '../../../Primitive/SmartLink'
import Icon from '../../../Primitive/Icon'
import Type from '../../../Primitive/Type'
import TextAlign from '../../../Primitive/TextAlign'
import Modal from '../../../Primitive/Modal'
import Prose from '../../../Primitive/Prose'

const TermsLink = ({ terms }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const handleOnClick = () => {
    setModalOpen(true)
  }
  const onModalClose = () => {
    setModalOpen(false)
  }
  return (
    <div className="RedemptionPanelTerms">
      <TextAlign center>
        <SmartLink
          className="RedemptionPanelLinkWrapper"
          onClick={handleOnClick}
        >
          <Type
            className="RedemptionPanelLink"
            as="span"
            size="RedemptionPanelLink"
          >
            Terms and conditions
          </Type>
          <Icon
            vAlign="middle"
            className="RedemptionPanelLinkIcon"
            type="ChevronRight"
          />
        </SmartLink>
      </TextAlign>
      <Modal
        title="Terms and conditions"
        isOpen={modalOpen}
        onClose={onModalClose}
      >
        <Prose dangerousHtml={terms} />
      </Modal>
    </div>
  )
}

TermsLink.propTypes = {
  terms: PropTypes.string,
}

export default TermsLink
