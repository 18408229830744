import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../../../Primitive/Icon'
import classNames from 'classnames'

export function GSMapCard(props) {
  const { offer, onClick, className } = props

  return (
    <button
      aria-label="Open Offer"
      className={classNames('GSMapCard', className)}
      onClick={onClick}
    >
      <img src={offer.image} alt={offer.name} />
      <div className="GSMapCardInfo">
        <h3 className="GSMapCardName">{offer.name}</h3>
        <div className="GSMapCardLabel">{offer.offer.name}</div>
        <div className="GSMapCardInfoDetails">
          <div className="GSMapCardLabel">
            {offer.offer.formattedAvailability}
          </div>
          {/* <div className="GSMapCardLabel">
            {offer.offer.availableServices.map((s) => s.name).join(', ')}
          </div> */}
          {offer.distance && (
            <div className="GSMapCardDistance">
              <Icon
                type="Location"
                width={10}
                height={10}
                a11yText="Location"
              />
              {offer.distance}
            </div>
          )}
        </div>
      </div>
    </button>
  )
}

GSMapCard.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  offer: PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.string,
    distance: PropTypes.string,
    geo: PropTypes.shape({
      lat: PropTypes.number,
      long: PropTypes.number,
    }),
    offer: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      formattedAvailability: PropTypes.string,
      availableServices: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    }),
  }),
}
