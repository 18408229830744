const map = {
  marketingOptIn:
    '<p>By ticking this box, you are acknowledging that you are happy to receive marketing messages from The Telegraph</p>',
  sportNewsletter:
    "<p>Tick here to sign up to The Telegraph's Sport Briefing newsletter to receive the best of our sport coverage - every weekday morning.</p>",
  filmNewsletter:
    "<p>Tick here to sign up to The Telegraph's Film newsletter as Robbie Collin shares his thoughts on the week's best movies and film news - every Friday.</p>",
  tvNewsletter:
    "<p>Tick here to sign up to The Telegraph's Talking TV newsletter for our guide to the week's top TV shows - every Monday.</p>",
  cultureNewsletter:
    "<p>Tick here to sign up to The Telegraph's Culture newsletter for our weekly guide to enjoying the arts at home, from books and box sets to Bach and the Bard - every Thursday.</p>",
  cookbookNewsletter:
    "<p>Tick here to sign up to The Telegraph's Cookbook newsletter for a feast of ideas from our recipe site, including Diana Henry's greatest hits and inspiration for what to cook tonight - every weekday.</p>",
  gardeningNewsletter:
    "<p>Tick here to sign up to The Telegraph's Gardening newsletter for practical tips from our expert writers, including Bunny Guinness and Tom Brown, and features on seasonal plants to grow - every Friday.</p>",
  travelsolutionNewsletter:
    "<p>Tick here to sign up to The Telegraph's weekly Travel Solutions newsletter containing great deals on holidays, tours, cruises and hotels. </p>",
  traveldealsNewsletter:
    "<p>Tick here to sign up to The Telegraph's Travel Deals newsletter containing the best deals of the week brought to you by a wide range of selected advertisers.</p>",
  travelNewsletter:
    "<p>Tick here to sign up to The Telegraph's Travel newsletter containing destination guides, travel advice and the latest news from our experts around the world - every Wednesday and Sunday.</p>",
  lifestyleNewsletter:
    "<p>Tick here to sign up to The Telegraph's Lifestyle newsletter for your daily slice of fashion, beauty, lifestyle and features.</p>",
  healthNewsletter:
    "<p>Tick here to sign up to The Telegraph's Healthy Living newsletter for the latest health and wellbeing stories, including simple ways to get fit, boost your mood and keep your mind sharp - every Tuesday.</p>",
}

export default map
