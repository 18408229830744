import React from 'react'
import { bool, node, string } from 'prop-types'
import classNames from 'classnames'

import ButtonBase from '../ButtonBase'

const ButtonStandard = ({
  children,
  className,
  disabled,
  secondary,
  tertiary,
  transparent,
  alternative,
  clipped,
  variable,
  wide,
  block,
  shadow,
  ...other
}) => (
  <ButtonBase
    className={classNames(
      'ButtonStandard',
      disabled && 'disabled',
      secondary && 'secondary',
      tertiary && 'tertiary',
      transparent && 'transparent',
      alternative && 'alternative',
      clipped && 'clipped',
      variable && 'variable',
      wide && 'wide',
      block && 'block',
      shadow && 'shadow',
      className
    )}
    disabled={disabled}
    {...other}
  >
    <div className="ButtonStandardInner">
      <div className="ButtonStandardLabel">{children}</div>
    </div>
  </ButtonBase>
)

ButtonStandard.propTypes = {
  children: node,
  className: string,
  disabled: bool,
  secondary: bool,
  tertiary: bool,
  transparent: bool,
  alternative: bool,
  clipped: bool,
  dropdown: bool,
  variable: bool,
  wide: bool,
  block: bool,
  shadow: bool,
}

export default ButtonStandard
