/**
 * Haversine formula to calculate the distance between two coordinates
 **/
function getDistance(coord1, coord2) {
  const R = 3959 // Radius of the Earth in miles
  const toRadians = (angle) => angle * (Math.PI / 180)

  const lat1 = toRadians(coord1.lat)
  const lng1 = toRadians(coord1.lng)
  const lat2 = toRadians(coord2.lat)
  const lng2 = toRadians(coord2.lng)

  const dLat = lat2 - lat1
  const dLon = lng2 - lng1

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  const distance = R * c
  return distance
}

export default getDistance
