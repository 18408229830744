import React from 'react'
import PropTypes from 'prop-types'

import SmartLink from '../../../Primitive/SmartLink'
import Icon from '../../../Primitive/Icon'
import Type from '../../../Primitive/Type'
import TextAlign from '../../../Primitive/TextAlign'

const LoginLink = ({ currentUrl }) => {
  const loginLink = `https://secure.telegraph.co.uk/secure/login/?ICID=subs-offers_rewards_onsite-asset_generic_telegraphextra&redirectTo=${encodeURIComponent(
    currentUrl
  )}&source=extramicrosite`
  return (
    <div className="RedemptionPanelLogInLink">
      <TextAlign center>
        <Type as="span" size="RedemptionPanelLink">
          Already a subscriber?{' '}
        </Type>
        <SmartLink className="RedemptionPanelLinkWrapper" href={loginLink}>
          <Type
            className="RedemptionPanelLink"
            as="span"
            size="RedemptionPanelLink"
          >
            Log in
          </Type>
          <Icon
            vAlign="middle"
            className="RedemptionPanelLinkIcon"
            type="ChevronRight"
          />
        </SmartLink>
      </TextAlign>
    </div>
  )
}

LoginLink.propTypes = {
  currentUrl: PropTypes.string,
}

export default LoginLink
