import React from 'react'
import classNames from 'classnames'
import { node, string } from 'prop-types'
import { bool } from 'prop-types'

// // import styles from "./CheckControlGroup.scss";

const CheckControlGroup = ({
  a11yLabel,
  children,
  hasBorder,
  additionalClassName,
}) => (
  <div
    className={classNames(
      'CheckControlGroup',
      hasBorder && 'CheckControlBorder',
      additionalClassName
    )}
    role="group"
    aria-label={a11yLabel}
  >
    {children}
  </div>
)

CheckControlGroup.propTypes = {
  a11yLabel: string.isRequired,
  additionalClassName: string,
  children: node.isRequired,
  hasBorder: bool,
}

export default CheckControlGroup
