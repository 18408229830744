import React from 'react'
import PropTypes from 'prop-types'

import Type from '../../../Type'
import classNames from 'classnames'

const SummaryStatus = ({
  category,
  status,
  __userHasPreviouslyRedeemed,
  hideStatus,
  featuredOffer,
}) => {
  const categoryStatusMap = {
    Competition: 'You have entered',
    Event: 'You have booked',
  }

  if (categoryStatusMap[category] && __userHasPreviouslyRedeemed) {
    return (
      !hideStatus && (
        <div
          className={classNames(
            'SummaryStatus',
            featuredOffer && 'FeaturedSummaryStatus'
          )}
        >
          <Type size="SummaryStatus">{categoryStatusMap[category]}</Type>
        </div>
      )
    )
  }
  return (
    <>
      {status && status !== 'Available' && !hideStatus && (
        <div
          className={classNames(
            'SummaryStatus',
            featuredOffer && 'FeaturedSummaryStatus'
          )}
        >
          <Type size="SummaryStatus">{status}</Type>
        </div>
      )}
    </>
  )
}

SummaryStatus.propTypes = {
  category: PropTypes.string,
  status: PropTypes.string,
  hideStatus: PropTypes.bool,
  __userHasPreviouslyRedeemed: PropTypes.bool,
  featuredOffer: PropTypes.bool,
}
export default SummaryStatus
