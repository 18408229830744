async function fetchRestaurants(params) {
  const { useUrlParams = true } = params
  const query = buildRestaurantSearchQuery(params)
  const url = new URL(window.location)
  url.search = query
  if (useUrlParams) {
    window.history.pushState({}, '', url)
  }

  const restaurants = await fetch(
    '/api/gourmet-society/restaurants' + url.search
  )
  const data = await restaurants.json()
  return data
}

export function buildRestaurantSearchQuery(params) {
  const { location, filters, query, limit, page, type, bounds } = params

  const filterParams = {}

  Object.keys(filters).forEach((filterKey) => {
    const filter = filters[filterKey]
    if (filter.length > 0) {
      const filterValues = filter.filter((f) => f.checked).map((f) => f.value)
      if (filterValues.length > 0) {
        if (filterKey === 'daysAvailable') {
          const days = []
          const todayFilter = filterValues.find((f) => f === 'today')
          const everydayFilter = filterValues.find(
            (f) =>
              f === 'monday,tuesday,wednesday,thursday,friday,saturday,sunday'
          )
          if (todayFilter) {
            filterValues.splice(filterValues.indexOf('today'), 1)
            days.push(
              new Date()
                .toLocaleString('en-us', { weekday: 'long' })
                .toLowerCase()
            )
          }
          if (everydayFilter) {
            days.push(...everydayFilter.split(','))
          } else {
            days.push(filterValues)
          }
          const uniqueDays = [...new Set(days.flat())]
          filterParams.daysAvailable = uniqueDays
          return
        }
        filterParams[filterKey] = filterValues
      }
    }
  })

  // Check if Independent or Chain is selected
  delete filterParams.restaurantType
  const restaurantType = filters.restaurantType.find((f) => f.checked)

  const isIndependent = restaurantType && restaurantType.value === 'independent'
  const isChain = restaurantType && restaurantType.value === 'chain'

  const searchQuery = {
    limit: limit || 12,
    page: page || 1,
    ...filterParams,
    ...(query && { q: query }),
    ...(type && { type: type }),
    ...(isIndependent && { independent: true }),
    ...(isChain && { independent: false }),
    ...(bounds && {
      bounds: [bounds.west, bounds.south, bounds.east, bounds.north],
    }),
    ...(location && {
      aroundLatLng: `${location.lat},${location.lng}`,
      distance: 50, // km
    }),
  }

  return new URLSearchParams(searchQuery).toString()
}

export default fetchRestaurants
