import React from 'react'

import ButtonStandard from '../../../Primitive/ButtonStandard'

const FAQsButton = () => {
  return (
    <div className="FAQsButtonWrapper">
      <ButtonStandard clipped href="/faqs">
        FAQs
      </ButtonStandard>
    </div>
  )
}

export default FAQsButton
