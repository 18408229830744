import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

function GSCheckbox(props) {
  const {
    id,
    label,
    onChange,
    value,
    checked,
    type = 'checkbox',
    className,
    ...rest
  } = props

  return (
    <div className={classNames('GSCheckboxControl', type, className)}>
      <span className="GSCheckboxControlWrapper">
        <input
          className="GSCheckboxControlControl"
          id={id}
          checked={checked}
          onChange={onChange}
          type={type}
          value={value}
          {...rest}
        />
        <span className="GSCheckboxControlIndicator" />
      </span>
      <label htmlFor={id}>{label}</label>
    </div>
  )
}

GSCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  type: PropTypes.string,
}

export default GSCheckbox
