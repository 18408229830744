import React from 'react'
import classNames from 'classnames'
import { bool, node, oneOf } from 'prop-types'

// import styles from "./ShrinkWrapItem.scss";

const ShrinkWrapItem = ({ children, gap, noWrap, shrink, vAlign }) => (
  <span
    className={classNames(
      'ShrinkWrapItem',
      gap && gap,
      noWrap && 'noWrap',
      shrink && 'shrink',
      vAlign && vAlign
    )}
  >
    {children}
  </span>
)

ShrinkWrapItem.displayName = 'ShrinkWrapItem'

ShrinkWrapItem.propTypes = {
  children: node.isRequired,
  shrink: bool,
  noWrap: bool,
  gap: oneOf(['large', 'default', 'none']),
  vAlign: oneOf(['top', 'middle', 'bottom']),
}

export default ShrinkWrapItem
