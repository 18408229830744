import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import RedemptionArea from '../RedemptionArea'
import ButtonStandard from '../Primitive/ButtonStandard'
import Type from '../Primitive/Type'
import UmbrellaChildInstructionsLink from '../RedemptionPanel/component/UmbrellaChildInstructionsLink'
import TermsLink from '../RedemptionPanel/component/TermsLink'
import { UmbrellaOffersRedemptionContext } from '../Context/UmbrellaOffersRedemptionContext'

const getRedemptionAreaPropsFromPanelProps = (panelProps) => {
  const {
    offerId,
    redemptionStatus,
    redemptionLimit,
    redemptionCount,
    headline,
    formData,
    redemptionExperiences,
    isTwoStepRedeem,
    userHasPreviouslyRedeemed,
    shouldShowKeyFacts,
    shouldShowSubscriptionUpsell,
    shouldShowSubscribeButton,
    shouldShowCustomMessage,
    shouldShowUmbrellaChildInstructions,
    umbrellaChildInstructions,
    isSubscriber,
    isEligibleSubscriber,
    hasExtraAccess,
    hasPuzzlesAccess,
    hasWineAccess,
    redemptionMessaging,
    isLoggedIn,
    isRegistrant,
    shouldShowLoginLink,
    shouldShowTerms,
    termsAndConditions,
    addToCalendarEnabled,
    addToCalendarStartDate,
    userData,
    unavailableMessaging,
    currentUrl,
    offerCategory,
    stripePublicKey,
    isModalOpen,
    stripeJsVersion,
  } = panelProps

  return {
    offerId,
    redemptionStatus,
    redemptionLimit,
    redemptionCount,
    keyFacts: [],
    headline,
    isSubscriber,
    isEligibleSubscriber,
    hasExtraAccess,
    hasPuzzlesAccess,
    hasWineAccess,
    redemptionMessaging,
    isLoggedIn,
    isRegistrant,
    keyFactsPresent: false,
    redemptionExperiences,
    isTwoStepRedeem,
    addToCalendarEnabled,
    addToCalendarStartDate,
    userHasPreviouslyRedeemed,
    shouldShowSubscribeButton,
    shouldShowSubscriptionUpsell,
    shouldShowCustomMessage,
    shouldShowKeyFacts,
    shouldShowLoginLink,
    shouldShowTerms,
    termsAndConditions,
    formData,
    userData,
    unavailableMessaging,
    currentUrl,
    offerCategory,
    stripePublicKey,
    showShortcutButton: false,
    isModalOpen,
    stripeJsVersion,
    shouldShowUmbrellaChildInstructions,
    umbrellaChildInstructions,
  }
}

const UmbrellaChildOffer = (props) => {
  const {
    headline,
    sell,
    children,
    openChildOffer,
    url,
    panelProps,
    category,
    imageUrl,
    consequentOffers,
    isUmbrella,
  } = props
  const redemptionAreaProps = getRedemptionAreaPropsFromPanelProps(panelProps)
  const isLoggedIn = redemptionAreaProps.isLoggedIn || false
  const { redeemedUmbrellaOffers } = useContext(UmbrellaOffersRedemptionContext)

  // If any of these are true do not show the redemption area as the user is not logged in or eligible.
  // Additional panel will be shown above child offers suggesting next action for the user
  const showRedemptionArea =
    !panelProps.shouldShowSubscriptionUpsell &&
    !panelProps.shouldShowSubscribeButton &&
    !panelProps.shouldShowCustomMessage &&
    !panelProps.shouldShowLoginLink

  return (
    <>
      <div className={classNames('UmbrellaChildOffer')}>
        {imageUrl && (
          <div className="UmbrellaChildLeft">
            <picture>
              <img src={imageUrl} alt={headline} className="OfferImagesImage" />
            </picture>
          </div>
        )}
        <div className="UmbrellaChildMiddle">
          <div className="UmbrellaChildHeaderText">
            <Type as="h3" className="TypeHeading3">
              {headline}
            </Type>
            {sell && (
              <div
                className="UmbrellaChildOfferSell TypeBase"
                dangerouslySetInnerHTML={{ __html: sell }}
              />
            )}
            {panelProps.shouldShowUmbrellaChildInstructions && (
              <UmbrellaChildInstructionsLink
                instructions={panelProps.umbrellaChildInstructions}
              />
            )}
            {isUmbrella && redemptionAreaProps.termsAndConditions && (
              <TermsLink terms={redemptionAreaProps.termsAndConditions} />
            )}
          </div>
        </div>
        {isLoggedIn && (
          <div className="UmbrellaChildRight">
            <div className="UmbrellaChildRedemptionAreaContainer">
              {openChildOffer && (
                <div className="RedemptionArea">
                  <div className="RedemptionButton RedemptionButtonUmbrella">
                    <ButtonStandard
                      className="RedemptionButtonButton"
                      clipped
                      secondary
                      href={url}
                    >
                      {`View ${category}`}
                    </ButtonStandard>
                  </div>
                </div>
              )}
              {!openChildOffer && showRedemptionArea && (
                <div className="UmbrellaChildRedemptionArea">
                  <RedemptionArea
                    isUmbrella={isUmbrella}
                    isUmbrellaView
                    consequentOffers={consequentOffers || []}
                    redeemedUmbrellaOffers={redeemedUmbrellaOffers}
                    {...redemptionAreaProps}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {children && children}
    </>
  )
}

UmbrellaChildOffer.propTypes = {
  headline: PropTypes.string,
  sell: PropTypes.string,
  termsAndConditions: PropTypes.string,
  children: PropTypes.node,
  openChildOffer: PropTypes.bool,
  url: PropTypes.string,
  panelProps: PropTypes.object,
  imageUrl: PropTypes.string,
  category: PropTypes.string,
  consequentOffers: PropTypes.array,
  isUmbrella: PropTypes.any,
}

export default UmbrellaChildOffer
