import React from 'react'
import { bool, number, string } from 'prop-types'
import classNames from 'classnames'

// import styles from "./IconButton.scss";

import Icon from '../Icon/'
import SmartLink from '../SmartLink'

const IconButton = ({
  a11yText,
  children,
  className,
  hoverGrow,
  icon,
  iconHeight,
  iconWidth,
  increaseHitArea,
  large,
  light,
  rounded,
  small,
  solid,
  ...other
}) => (
  <SmartLink
    className={classNames(
      'IconButton',
      hoverGrow && 'hoverGrow',
      increaseHitArea && 'increaseHitArea',
      rounded && 'rounded',
      large && 'large',
      light && 'light',
      small && 'small',
      solid && 'solid',
      className
    )}
    {...other}
  >
    <div className="IconButtonInner">
      <Icon
        type={icon}
        height={iconHeight}
        width={iconWidth}
        a11yText={a11yText}
      />
      {children && <span className="IconButtonText">{children}</span>}
    </div>
  </SmartLink>
)

IconButton.propTypes = {
  a11yText: string.isRequired,
  children: string,
  className: string,
  hoverGrow: bool,
  icon: string.isRequired,
  iconHeight: number,
  iconWidth: number,
  increaseHitArea: bool,
  large: bool,
  light: bool,
  rounded: bool,
  small: bool,
  solid: bool,
}

export default IconButton
