import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Icon from '../../../Primitive/Icon'
import {
  autoUpdate,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react'

function GSMapFilterSelect(props) {
  const { label, options = [], onOptionChange, activeOption } = props
  const [isOpen, setIsOpen] = useState(false)

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    placement: 'bottom-start',
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    transform: true,
    middleware: [offset(8), shift()],
  })

  const click = useClick(context)
  const dismiss = useDismiss(context)
  const role = useRole(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ])

  if (!options) return null
  return (
    <div
      className={classNames(
        'GSMapFilterPanelSelect',
        isOpen && 'GSMapFilterPanelSelect--active'
      )}
    >
      <button
        ref={refs.setReference}
        className={classNames(
          'GSMapFilterPanelToggle',
          isOpen && 'GSMapFilterPanelToggle--active'
        )}
        {...getReferenceProps()}
      >
        <div className="GSMapFilterPanelToggleLabel">
          {label}: <span>{activeOption.name}</span>
        </div>
        <Icon
          className="GSMapFilterPanelToggleChevron"
          type="GSChevron"
          width={8}
          height={8}
          a11yText="Toggle filter"
        />
      </button>
      {isOpen && (
        <div
          ref={refs.setFloating}
          className="GSMapFilterPanelWrapper"
          style={floatingStyles}
          {...getFloatingProps()}
        >
          <div className="GSMapFilterSelectPanelInner">
            <div className="GSMapFilterSelectOptions">
              {options.map((option) => (
                <button
                  key={option.name}
                  onClick={() => {
                    onOptionChange(option)
                    setIsOpen(false)
                  }}
                  className={classNames(
                    'GSMapFilterSelectItem',
                    activeOption.value === option.value &&
                      'GSMapFilterSelectItem--selected'
                  )}
                >
                  {option.name}
                  {activeOption.value === option.value && (
                    <Icon
                      type="GSCheck"
                      a11yText="Selected"
                      width={20}
                      height={20}
                    />
                  )}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

GSMapFilterSelect.propTypes = {
  label: PropTypes.string,
  onOptionChange: PropTypes.func,
  activeOption: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ),
}

export default GSMapFilterSelect
