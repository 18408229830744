import formatAvailability from './format-availability'
import formatAddress from './format-address'
import versionPath from '../../../../../../site/lib/version-path'

function formatRestaurant(restaurant) {
  if (!restaurant) return null
  if (!restaurant.offers || !restaurant.offers.length) return null

  const offers = restaurant.offers.map((offer) => {
    return {
      id: restaurant.id,
      name: restaurant.name,
      image: restaurant.primaryImage
        ? restaurant.primaryImage.href
        : versionPath('/assets/img/content/gs-placeholder.png'),
      description: restaurant.description,
      cuisines: restaurant.cuisines,
      independent: restaurant.independent,
      address: formatAddress(restaurant.address),
      bookingTelephone: restaurant.bookingTelephone,
      chain: restaurant.chain,
      brand: restaurant.brand,
      offer: {
        id: offer.id,
        name: offer.name,
        availability: offer.availability,
        formattedAvailability: formatAvailability(offer.availability),
        genericExclusions: offer.genericExclusions,
        maxDiners: offer.maxDiners,
        availableServices: offer.availableServices,
        redemption: offer.redemption,
        claimMethods: offer.claimMethods,
      },
      ...(restaurant.geo && {
        geo: {
          lat: restaurant.geo.lat,
          lng: restaurant.geo.long,
        },
      }),
    }
  })

  return offers
}

export default formatRestaurant
