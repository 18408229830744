import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Icon from '../../../Primitive/Icon'
import GSFloatingPanel from '../GSFloatingPanel'

function GSFilterSelect(props) {
  const { label, options, onOptionChange } = props
  const [isOpen, setIsOpen] = useState(false)

  if (!options) return null

  const hasActiveFilters = options.some((option) => option.checked)

  return (
    <GSFloatingPanel
      label={label}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      isActive={hasActiveFilters}
    >
      <div className="GSFilterSelectPanelInner">
        <div className="GSFilterSelectOptions">
          {options.map((option) => (
            <button
              key={option.label}
              onClick={() => {
                onOptionChange(option)
                setIsOpen(false)
              }}
              className={classNames(
                'GSFilterSelectItem',
                option.checked && 'GSFilterSelectItem--selected'
              )}
            >
              {option.label}
              {option.checked && (
                <Icon
                  type="GSCheck"
                  a11yText="Selected"
                  width={20}
                  height={20}
                />
              )}
            </button>
          ))}
        </div>
      </div>
    </GSFloatingPanel>
  )
}

GSFilterSelect.propTypes = {
  label: PropTypes.string,
  onOptionChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      checked: PropTypes.bool,
    })
  ),
}

export default GSFilterSelect
