import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ButtonStandard from '../../../Primitive/ButtonStandard'

const AddToCalendar = ({ offerId }) => {
  const [addToCalendarClicked, setAddToCalendarClicked] = useState(false)
  const onClick = () => {
    setAddToCalendarClicked(true)
  }
  const baseLink = `/api/calendar?id=${offerId}&type=`
  const iCalLink = `${baseLink}ical`
  const googleCalLink = `${baseLink}gcal`
  return (
    <>
      {!addToCalendarClicked && (
        <ButtonStandard
          onClick={onClick}
          className="AddToCalendar"
          tertiary
          clipped
          block
        >
          Add to calendar
        </ButtonStandard>
      )}
      {addToCalendarClicked && (
        <>
          <ButtonStandard href={iCalLink} className="AddToCalendar" tertiary>
            iCal Calendar
          </ButtonStandard>
          <ButtonStandard
            href={googleCalLink}
            target="_blank"
            className="AddToCalendar"
            tertiary
          >
            Google Calendar
          </ButtonStandard>
        </>
      )}
    </>
  )
}

AddToCalendar.propTypes = {
  offerId: PropTypes.string,
}

export default AddToCalendar
