import React from 'react'
import ReactDOM from 'react-dom'
import OfferImages from '../../../site/layout/component/OfferImages'

const debug = require('debug')('offer-images')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.js-offer-images').forEach((appNode) => {
      const props = JSON.parse(appNode.getAttribute('data-props') || '{}')
      ReactDOM.render(<OfferImages isRehydrated {...props} />, appNode)
    })
  })
}
