import { object } from 'prop-types'
import React from 'react'

import Type from '../../../Primitive/Type/index'
import ButtonStandard from '../../../Primitive/ButtonStandard/index'

function CustomMessage({ redemptionMessaging }) {
  return (
    <div className="SubscriptionMessage">
      <Type
        className="SubscriptionUpsellTitle"
        as="p"
        size="SubscriptionUpsellTitle"
      >
        {redemptionMessaging && redemptionMessaging.title && (
          <div
            dangerouslySetInnerHTML={{
              __html: redemptionMessaging.title,
            }}
          ></div>
        )}
      </Type>
      <Type
        className="SubscriptionUpsellDescription"
        as="p"
        size="SubscriptionUpsellDescription"
      >
        {redemptionMessaging && redemptionMessaging.description && (
          <div
            dangerouslySetInnerHTML={{
              __html: redemptionMessaging.description,
            }}
          ></div>
        )}
      </Type>
      <div className="CustomButtonWrapper">
        <ButtonStandard
          className="SubscribeButton"
          clipped
          href={redemptionMessaging && redemptionMessaging.buttonLink}
        >
          {redemptionMessaging && redemptionMessaging.buttonText}
        </ButtonStandard>
      </div>
    </div>
  )
}

CustomMessage.propTypes = {
  redemptionMessaging: object,
}

export default CustomMessage
