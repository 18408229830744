import React from 'react'
import classNames from 'classnames'
import { node, string, bool, func } from 'prop-types'

const NavigationItem = ({
  children,
  url,
  bordered,
  padded,
  hideBelow,
  hideAt,
  onClick,
  active,
  element = 'a',
  target,
  rel,
  currentSection,
}) => {
  const Wrapper = element

  return (
    <Wrapper
      className={classNames(
        'NavigationItem',
        bordered && 'bordered',
        padded && 'padded',
        currentSection && currentSection === url && 'highlighted',
        hideBelow && `Hide below-${hideBelow}`,
        hideAt && `Hide at-${hideAt}`,
        active && 'active'
      )}
      href={url}
      onClick={onClick}
      target={target}
      rel={rel}
    >
      {children}
    </Wrapper>
  )
}

NavigationItem.propTypes = {
  children: node,
  url: string,
  bordered: bool,
  padded: bool,
  hideBelow: string,
  hideAt: string,
  onClick: func,
  active: bool,
  target: string,
  element: string,
  rel: string,
  currentSection: string,
}

export default NavigationItem
