import React from 'react'
import { bool, object, func, string } from 'prop-types'

import NavigationItem from './NavigationItem'

import Type from '../../component/Primitive/Type'
import Icon from '../../component/Primitive/Icon'

const UserMenu = ({
  hideBelow,
  hideAt,
  isLoggedIn,
  isOpen,
  user,
  handleOpen,
  currentUrl,
}) => {
  const handleLogout = async () => {
    await window.tmguser.logout()
    window.location = '/auth/logout'
  }
  return (
    <>
      <NavigationItem
        hideBelow={hideBelow}
        hideAt={hideAt}
        bordered
        element={isLoggedIn ? 'button' : 'a'}
        url={`https://secure.telegraph.co.uk/secure/login/?ICID=subs-offers_rewards_onsite-asset_generic_telegraphextra&redirectTo=${encodeURIComponent(
          currentUrl
        )}&source=extramicrosite`}
        onClick={isLoggedIn ? handleOpen : undefined}
        active={isOpen}
      >
        <Icon
          type="User"
          className="NavigationUserIcon"
          vAlign="middle"
          width={16}
        />
        <Type as="span" size="Navigation">
          {isLoggedIn ? `Hi, ${user.firstName}` : 'Log In'}
        </Type>
        {isOpen && (
          <div className="NavigationUserMenu">
            <NavigationItem onClick={handleLogout}>
              <Type as="span" size="Navigation">
                Log out
              </Type>
              <Icon
                type="Logout"
                vAlign="middle"
                className="NavigationLogoutIcon"
                width={12}
              />
            </NavigationItem>
            <NavigationItem element="div">
              <Type size="Navigation">Logged in as:</Type>
              <Type className="NavigationUsername" size="Navigation">
                {user.email}
              </Type>
            </NavigationItem>
            <NavigationItem url="https://secure.telegraph.co.uk/customer/secure/account">
              <Type as="span" size="Navigation">
                Go to my account
              </Type>
              <Icon
                type="ArrowRight"
                vAlign="middle"
                className="NavigationArrowIcon"
                width={16}
              />
            </NavigationItem>
          </div>
        )}
      </NavigationItem>
    </>
  )
}

UserMenu.propTypes = {
  hideBelow: string,
  hideAt: string,
  isLoggedIn: bool,
  isOpen: bool,
  user: object,
  handleOpen: func,
  currentUrl: string,
}

export default UserMenu
