import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Modal from '../../../Primitive/Modal'
import Prose from '../../../Primitive/Prose'
import Icon from '../../../Primitive/Icon'

const UmbrellaChildInstructionsLink = ({ instructions }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const handleOnClick = () => {
    setModalOpen(true)
  }
  const onModalClose = () => {
    setModalOpen(false)
  }
  return (
    <div className="RedemptionPanelTerms">
      <div className="InstructionsLink">
        <a className="TypeRedemptionPanelLink" onClick={handleOnClick}>
          Instructions
          <Icon
            vAlign="middle"
            className="RedemptionPanelLinkIcon"
            type="ChevronRight"
          />
        </a>
      </div>
      <Modal title="Instructions" isOpen={modalOpen} onClose={onModalClose}>
        <Prose dangerousHtml={instructions} />
      </Modal>
    </div>
  )
}

UmbrellaChildInstructionsLink.propTypes = {
  instructions: PropTypes.string,
}

export default UmbrellaChildInstructionsLink
