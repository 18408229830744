import React from 'react'
import { node, bool } from 'prop-types'
import classNames from 'classnames'
import FieldRequired from '../FieldRequired'

const FieldAnswer = ({ required, children }) => (
  <div
    className={classNames(
      'FieldAnswer',
      children.props.type === 'inlineCheckbox' && 'FieldAnswerInlineCheckbox'
    )}
  >
    {children}
    {children.props.type === 'inlineCheckbox' && required && <FieldRequired />}
  </div>
)

FieldAnswer.propTypes = {
  children: node.isRequired,
  required: bool,
}

export default FieldAnswer
