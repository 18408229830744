import React, { useContext } from 'react'
import { bool, oneOf } from 'prop-types'
import classNames from 'classnames'

import { StatusContext } from '../../../Context/StatusContext'

// // import styles from "./CustomCheckControl.scss";

import NativeCheckControl from '../NativeCheckControl'

const CustomCheckControl = ({ status, type, inverse, ...other }) => {
  const contextStatus = useContext(StatusContext)
  const derivedStatus = status || contextStatus
  const componentClassName = classNames(
    'CustomCheckControl',
    type && type,
    derivedStatus && derivedStatus,
    inverse && 'inverse'
  )

  return (
    <span className={componentClassName}>
      <NativeCheckControl
        className="CustomCheckControlControl"
        type={type}
        {...other}
      />
      <span className="CustomCheckControlIndicator" />
    </span>
  )
}

CustomCheckControl.propTypes = {
  status: oneOf(['none', 'error', 'notice', 'success', 'warning']),
  type: oneOf(['checkbox', 'radio']).isRequired,
  inverse: bool,
}

export default CustomCheckControl
