import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Spring, animated } from 'react-spring'

import CheckControl from '../Primitive/CheckControl'
import classNames from 'classnames'
import Icon from '../Primitive/Icon'

const AgreeToTermsCheckbox = ({ terms }) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <div
      className={classNames(
        'AgreeToTermsCheckboxWrapper',
        expanded && 'expanded'
      )}
    >
      <div className="AgreeToTermsCheckbox">
        <CheckControl type="checkbox" value="checked" required>
          I have read the
        </CheckControl>
      </div>
      <button
        type="button"
        className="AgreeToTermsCheckboxToggle"
        onClick={() => setExpanded(!expanded)}
      >
        terms and conditions
        <Icon
          type="ChevronDown"
          className="AgreeToTermsCheckboxToggleIcon"
          width={15}
        />
      </button>
      <abbr className="FieldRequired" title="This field is required">
        *
      </abbr>
      <Spring
        native
        from={{ height: 0 }}
        to={{ height: expanded ? 'auto' : 0 }}
      >
        {(props) => (
          <animated.div
            className="AgreeToTermsCheckboxTermsWrapper"
            style={props}
          >
            <div
              className="Type TypeTerms AgreeToTermsCheckboxTerms"
              dangerouslySetInnerHTML={{ __html: terms }}
            />
          </animated.div>
        )}
      </Spring>
    </div>
  )
}

AgreeToTermsCheckbox.propTypes = {
  terms: PropTypes.string,
}

export default AgreeToTermsCheckbox
