import React from 'react'
import { bool, node, oneOf, string } from 'prop-types'
import classNames from 'classnames'
import Element from '../Element'

export const sizes = [
  'small',
  'medium',
  'mediumLarge',
  'large',
  'wide',
  'narrow',
  'auto',
]

const Container = ({
  as,
  center,
  children,
  className,
  gutter,
  largeGutter,
  noClearfix,
  size,
}) => (
  <Element
    as={as}
    className={classNames(
      'Container',
      center && 'center',
      gutter && 'gutter',
      largeGutter && 'largeGutter',
      noClearfix && 'noClearfix',
      size && size,
      className
    )}
  >
    {children}
  </Element>
)

Container.propTypes = {
  as: string,
  center: bool,
  children: node.isRequired,
  className: string,
  gutter: bool,
  largeGutter: bool,
  noClearfix: bool,
  size: oneOf(sizes),
}

export default Container
