import { bool } from 'prop-types'
import React from 'react'

import Type from '../../../Primitive/Type'

const SubscriptionUpsell = ({ hasExtraAccess, isSubscriber, isUpgradable }) => {
  const Title = () => {
    if (isSubscriber && !hasExtraAccess) {
      return <div>You don&apos;t have access to Telegraph Extra</div>
    }
    return 'Subscribe now to enjoy Telegraph Extra'
  }

  const Description = () => {
    if (isSubscriber && !hasExtraAccess) {
      if (isUpgradable) {
        return 'Upgrade to a Digital Subscription and enjoy exciting offers, events and competitions, plus unlimited access to our website and app.'
      } else {
        return 'Click the button below for more information on your eligibility.'
      }
    }
    return 'Subscribe to enjoy hand-picked rewards from Telegraph Extra, as well as our award-winning journalism.'
  }

  return (
    <div className="SubscriptionUpsell">
      <Type
        className="SubscriptionUpsellTitle"
        as="p"
        size="SubscriptionUpsellTitle"
      >
        <Title />
      </Type>
      <Type
        className="SubscriptionUpsellDescription"
        as="p"
        size="SubscriptionUpsellDescription"
      >
        <Description />
      </Type>
    </div>
  )
}

SubscriptionUpsell.propTypes = {
  hasExtraAccess: bool,
  isSubscriber: bool,
  isUpgradable: bool,
}

export default SubscriptionUpsell
