import React from 'react'
import PropTypes from 'prop-types'
import FieldTemplate from '../Primitive/FieldTemplate'

import TextControl from '../Primitive/TextControl'
import SelectControl from '../Primitive/SelectControl'
import CheckControl from '../Primitive/CheckControl'
import CheckControlGroup from '../Primitive/CheckControlGroup'
import specialCheckboxLabelMap from './special-checkbox-label-mapper'
import Prose from '../Primitive/Prose'

const textType = (props) => <TextControl {...props} />
const emailType = (props) => <TextControl {...props} type="email" />
const phoneNumberType = (props) => <TextControl {...props} type="tel" />
const textAreaType = (props) => <TextControl multiLine {...props} />
const selectType = (props) => (
  <SelectControl {...props}>
    {props.options &&
      props.options.map((option, i) => (
        <option key={i} value={option.value}>
          {option.text}
        </option>
      ))}
  </SelectControl>
)
const inlineCheckboxType = (props) => (
  <CheckControl
    {...props}
    type="checkbox"
    hasBorder
    value="checked"
    vAlign="top"
  >
    <Prose dangerousHtml={props.label} />
  </CheckControl>
)
const specialInlineCheckboxType = (props) => {
  const label = specialCheckboxLabelMap[props.type]
  if (!label) return null
  return (
    <CheckControl
      {...props}
      type="checkbox"
      hasBorder
      value="checked"
      vAlign="top"
    >
      <div dangerouslySetInnerHTML={{ __html: label }} />
    </CheckControl>
  )
}
const checkboxGroupType = (props) => (
  <CheckControlGroup {...props} hasBorder>
    {props.options &&
      props.options.map((option, i) => (
        <CheckControl key={i} {...props} value={option.text}>
          {option.text}
        </CheckControl>
      ))}
  </CheckControlGroup>
)
const radioType = (props) => (
  <CheckControlGroup {...props} hasBorder>
    {props.options &&
      props.options.map((option, i) => (
        <CheckControl key={i} {...props} value={option.text} type="radio">
          {option.text}
        </CheckControl>
      ))}
  </CheckControlGroup>
)

const inputTypeToComponent = {
  text: textType,
  email: emailType,
  phoneNumber: phoneNumberType,
  textarea: textAreaType,
  select: selectType,
  inlineCheckbox: inlineCheckboxType,
  checkbox: checkboxGroupType,
  radio: radioType,
  marketingOptIn: specialInlineCheckboxType,
  sportNewsletter: specialInlineCheckboxType,
  filmNewsletter: specialInlineCheckboxType,
  tvNewsletter: specialInlineCheckboxType,
  cultureNewsletter: specialInlineCheckboxType,
  cookbookNewsletter: specialInlineCheckboxType,
  gardeningNewsletter: specialInlineCheckboxType,
  travelsolutionNewsletter: specialInlineCheckboxType,
  traveldealsNewsletter: specialInlineCheckboxType,
  travelNewsletter: specialInlineCheckboxType,
  lifestyleNewsletter: specialInlineCheckboxType,
  healthNewsletter: specialInlineCheckboxType,
}

const optionMap = (item) => ({ text: item.response, value: item.response })

const FormBuilderQuestion = ({
  question,
  // onChange,
  data = {},
  errors = {},
  id,
}) => {
  const hasResponses = question.responses && question.responses.length > 0
  const options = hasResponses
    ? question.responses.map((response) => optionMap(response))
    : null

  const Component = inputTypeToComponent[question.inputType]
  if (!Component) return null

  const shouldHideLabel =
    question.inputType === 'inlineCheckbox' ||
    question.inputType === 'marketingOptIn' ||
    question.inputType.includes('Newsletter')

  const getStatus = (id) => {
    if (errors[`question-${id}`]) return 'error'
    else if (Object.keys(errors).length > 0) return 'success'
    return
  }

  return (
    <FieldTemplate
      controlName={`question-${id}`}
      value={data[`question-${id}`] || ''}
      feedback={errors[`question-${id}`]}
      type={question.inputType}
      wordLimit={question.wordLimit}
      template={question.inputType}
      label={shouldHideLabel ? null : question.question}
      required={!question.placeholderAsQuestion && question.required}
      assistance={question.description}
      status={getStatus(id)}
    >
      <Component
        name={`question-${id}`}
        // onChange={onChange}
        type={question.inputType}
        defaultValue={data[`question-${id}`]}
        status={errors[`question-${id}`] && 'error'}
        selected={data[`question-${id}`] || ''}
        label={question.question}
        options={
          question.inputType === 'select'
            ? [{ text: '--- Please Select ---', value: '' }, ...options]
            : options
        }
      />
    </FieldTemplate>
  )
}

FormBuilderQuestion.propTypes = {
  question: PropTypes.object,
  onChange: PropTypes.func,
  data: PropTypes.object,
  errors: PropTypes.object,
  id: PropTypes.number,
  config: PropTypes.object,
}

export default FormBuilderQuestion
