import React from 'react'
import { bool, node, string } from 'prop-types'

import Type from '../Type'
import classNames from 'classnames'

// import styles from "./CaptionedMedia.scss";

const CaptionedMedia = ({
  caption,
  children,
  figure,
  safeAreaLeft,
  safeAreaRight,
}) => {
  const BodyEl = figure ? 'figure' : 'div'
  const CaptionEl = figure ? 'figcaption' : 'div'

  if (!caption) return children

  return (
    <div className={classNames('CaptionedMedia', !caption && 'captionless')}>
      <BodyEl className="CaptionedMediaBody">{children}</BodyEl>
      {caption && (
        <Type
          size="CaptionedMedia"
          as={CaptionEl}
          className="CaptionedMediaCaption"
          style={{
            ...(safeAreaLeft && { paddingLeft: safeAreaLeft }),
            ...(safeAreaRight && { paddingRight: safeAreaRight }),
          }}
        >
          {caption}
        </Type>
      )}
    </div>
  )
}

CaptionedMedia.propTypes = {
  caption: node,
  children: node.isRequired,
  figure: bool,
  safeAreaLeft: string,
  safeAreaRight: string,
}

export default CaptionedMedia
