import React from 'react'
import PropTypes from 'prop-types'

import ButtonStandard from '../../../../Primitive/ButtonStandard'
import Type from '../../../../Primitive/Type'
import Grid from '../../../../Primitive/Grid'

const SquadUpFullPriceButton = ({ cost, onClick }) => (
  <div className="RedemptionButtonSplit">
    <Grid center>
      <Grid.Item middle width={{ m: 1 / 2 }}>
        <Type
          className="RedemptionButtonSplitTitle"
          size="RedemptionButtonSplitTitle"
        >
          Full Price
        </Type>
        <Type
          className="RedemptionButtonSplitDescription"
          size="RedemptionButtonSplitDescription"
        >
          {cost}
        </Type>
      </Grid.Item>
      <Grid.Item middle width={{ m: 1 / 2 }}>
        <ButtonStandard
          className="RedemptionButtonButton"
          tertiary
          block
          clipped
          onClick={onClick}
        >
          Buy tickets
        </ButtonStandard>
      </Grid.Item>
    </Grid>
  </div>
)

SquadUpFullPriceButton.propTypes = {
  onClick: PropTypes.func,
  cost: PropTypes.string,
}

export default SquadUpFullPriceButton
