import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import QRCode from 'react-qr-code'

export function GSMembershipOfferQR({
  code,
  restaurant,
  expirationDate,
  qrSrc,
}) {
  const formattedExpirationDate =
    expirationDate && moment(expirationDate).format('DD MMM YYYY HH:mm')

  return (
    <div className="GSMembershipOfferQR">
      <QRCode className="GSMembershipOfferQRCode" value={qrSrc} />
      <div className="GSMembershipOfferQRCodePlain">{code}</div>
      <div className="GSMembershipOfferQRNotice">
        You can only use two codes each day.
      </div>

      <div className="GSMembershipOfferQRType">{restaurant.offer.name}</div>
      <div className="GSMembershipOfferQRName">{restaurant.name}</div>
      {formattedExpirationDate && (
        <div className="GSMembershipOfferQRExpirationDate">
          Expires at: {formattedExpirationDate}
        </div>
      )}
    </div>
  )
}

GSMembershipOfferQR.propTypes = {
  code: PropTypes.string.isRequired,
  expirationDate: PropTypes.string.isRequired,
  qrSrc: PropTypes.string.isRequired,
  restaurant: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    offer: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  }),
}
