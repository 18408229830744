import React from 'react'
import { node } from 'prop-types'

const CheckControlText = ({ children }) => {
  return <div className="CheckControlText">{children}</div>
}

CheckControlText.propTypes = {
  children: node.isRequired,
}

export default CheckControlText
