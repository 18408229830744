import React from 'react'
import ReactDOM from 'react-dom'
import RedemptionPanel from '../../../site/layout/component/RedemptionPanel'

export default () => {
  const initialise = (appNode) => {
    const props = JSON.parse(appNode.dataset.props)
    if (
      window.location &&
      window.location.search &&
      window.location.search.includes('mopen=true')
    ) {
      props.isModalOpen = true
    }
    ReactDOM.render(<RedemptionPanel {...props} />, appNode)
  }

  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.js-redemption-panel').forEach(initialise)
  })
}
