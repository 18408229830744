import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../../../Primitive/Icon'
import GSButton from '../../GSButton'
import classNames from 'classnames'

function GSFiltersMobilePanel(props) {
  const {
    onOpenChange,
    onClear,
    onSubmit,
    focusRef,
    children,
    onBack,
    label,
    submitLabel,
    filterViewOpen,
  } = props

  return (
    <div className="GSFiltersMobilePanel">
      <div className="GSFiltersMobilePanelHeader">
        <button
          onClick={() => onBack(false)}
          aria-label="Go Back"
          className={classNames(
            'GSFiltersMobilePanelBack',
            onBack && 'GSFiltersMobilePanelBack--visible'
          )}
        >
          <Icon a11yText="Go Back" type="GSArrow" width={12} height={12} />
        </button>

        <div className="GSFiltersMobilePanelLabel">{label}</div>
        <button
          onClick={() => onOpenChange(false)}
          tabIndex={0}
          ref={focusRef}
          aria-label="Close panel"
          className="GSFiltersMobilePanelClose"
        >
          <Icon a11yText="Close" type="Close" width={12} height={12} />
        </button>
      </div>
      <div
        className={classNames(
          'GSFiltersMobilePanelInner',
          filterViewOpen && 'filterViewOpen'
        )}
      >
        {children}
      </div>
      <div className="GSFiltersMobilePanelFooter">
        {onClear && (
          <button
            onClick={() => onClear()}
            className="GSFiltersMobilePanelClear"
          >
            Clear All
          </button>
        )}
        <GSButton
          className="GSFiltersMobilePanelSubmit"
          size="sm"
          onClick={() => {
            onSubmit()
            if (!filterViewOpen) {
              onOpenChange(false)
            }
          }}
        >
          {submitLabel}
        </GSButton>
      </div>
    </div>
  )
}

GSFiltersMobilePanel.propTypes = {
  children: PropTypes.node,
  onOpenChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onClear: PropTypes.func,
  focusRef: PropTypes.object,
  onBack: PropTypes.func,
  label: PropTypes.string,
  submitLabel: PropTypes.string,
  filterViewOpen: PropTypes.bool,
}

export default GSFiltersMobilePanel
