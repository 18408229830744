import React from 'react'
import { bool } from 'prop-types'

import NavigationItem from './NavigationItem'
import Type from '../../component/Primitive/Type'
import Icon from '../../component/Primitive/Icon'

const TelegraphLink = ({ hideInPrimaryNav }) => (
  <NavigationItem
    hideBelow={hideInPrimaryNav && 'tabletLandscape'}
    bordered
    url="https://www.telegraph.co.uk/"
    target="_blank"
    rel="noopener noreferrer"
  >
    <Type as="span" size="Navigation">
      Telegraph.co.uk
    </Type>
    <Icon
      type="ArrowRight"
      vAlign="middle"
      className="NavigationArrowIcon"
      width={16}
    />
  </NavigationItem>
)

TelegraphLink.propTypes = {
  hideInPrimaryNav: bool,
}

export default TelegraphLink
