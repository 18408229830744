import React from 'react'
import classNames from 'classnames'
import {
  arrayOf,
  bool,
  node,
  number,
  object,
  oneOf,
  oneOfType,
  string,
} from 'prop-types'
// import sassKeysToArray from '../../../lib/sass-keys-to-array'

// import styles from "./GridItem.scss";

// const columns = sassKeysToArray(styles.columns)
const columns = ['m', 't', 'tl', 'd']
const trailingGaps = ['mobile', 'tablet', 'default']

const getWidthClasses = (widths) => {
  if (Array.isArray(widths)) {
    return widths.map((width, i) => `${columns[i]}-${Math.round(width * 100)}`)
  }
  return Object.entries(widths).map(
    (width) =>
      columns.includes(width[0]) && `${width[0]}-${Math.round(width[1] * 100)}`
  )
}

const GridItem = ({
  children,
  width,
  grow,
  middle,
  trailingGap,
  additionalClassName,
}) => (
  <div
    className={classNames(
      'GridItem',
      grow && 'grow',
      middle && 'middle',
      width && getWidthClasses(width),
      trailingGap && `trailingGap-${trailingGap}`,
      additionalClassName
    )}
  >
    {children}
  </div>
)

GridItem.displayName = 'GridItem'

GridItem.propTypes = {
  children: node.isRequired,
  trailingGap: oneOf(trailingGaps),
  grow: bool,
  middle: bool,
  width: oneOfType([arrayOf(number), object]),
  additionalClassName: string,
}

export default GridItem
