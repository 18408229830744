import React from 'react'
import { node, string } from 'prop-types'

const Element = ({ as = 'div', ...other }) => {
  const ElementEl = as

  return <ElementEl {...other} />
}

Element.propTypes = {
  children: node.isRequired,
  as: string,
}

export default Element
