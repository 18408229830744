import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import RedemptionButton from '../../RedemptionButton'
import RedemptionInstructions from '../../RedemptionInstructions'
import ThankYouMessaging from '../../ThankYouMessaging'
import ConfirmMaxRedemptionModal from '../../ConfirmMaxRedemptionModal'
import { UmbrellaOffersRedemptionContext } from '../../../../Context/UmbrellaOffersRedemptionContext'

const BasicRedemptionExperience = ({
  offerId,
  headline,
  isEligibleSubscriber,
  redemptionInstructions,
  redemptionButtonText,
  redemptionTypeProperties,
  isSecondaryExperience,
  userHasPreviouslyRedeemed,
  keyFactsPresent,
  isTwoStepRedeem,
  redemptionLimit,
  consequentOffers,
  isUmbrella,
  isUmbrellaView,
  umbrellaChildrenOpenInNewPage,
  userHasVipAccess,
  restrictToVipOnly,
}) => {
  const umbrellaOfferContext = useContext(UmbrellaOffersRedemptionContext)
  const { handleUmbrellaOfferRedemption, redeemedUmbrellaOffers = [] } =
    umbrellaOfferContext || {}

  if (!isEligibleSubscriber) return null

  const index = isSecondaryExperience ? 1 : 0
  const [requiresTwoStepsToRedeem, setRequiresTwoStepsToRedeem] =
    useState(isTwoStepRedeem)
  const [isRedeemed, setIsRedeemed] = useState(
    userHasPreviouslyRedeemed || redeemedUmbrellaOffers.includes(offerId)
  )
  const [isUnavailable, setIsUnavailable] = useState(false)
  const [instructions, setInstructions] = useState(redemptionInstructions)
  const [confirmMaxRedemptionModalOpen, setConfirmationMaxRedemptionModelOpen] =
    useState(false)

  const redemptionCtaText = requiresTwoStepsToRedeem
    ? 'Click to Reveal Code'
    : redemptionButtonText || 'Redeem'

  const twoStepRedeemOnClick = async () => {
    const res = await fetch(`/api/pre-redeem?offerId=${offerId}`, {
      headers: { 'content-type': 'application/json' },
      credentials: 'include',
      method: 'GET',
    })
    const data = await res.json()

    if (data.error) {
      setIsUnavailable(true)
    } else if (data.html) {
      setInstructions(data.html)
      setRequiresTwoStepsToRedeem(false)
      handleUmbrellaOfferRedemption && handleUmbrellaOfferRedemption(offerId)
      setIsRedeemed(true)
    }
    document.body.classList.remove('has-locked-scrolling')
  }

  const triggerMaxRedemptionConfirmation = () => {
    if (redemptionLimit > 0 && !isRedeemed) {
      setConfirmationMaxRedemptionModelOpen(true)
    } else if (requiresTwoStepsToRedeem) {
      twoStepRedeemOnClick()
    } else {
      goToUrl()
    }
  }

  const onAcceptClick = () => {
    if (requiresTwoStepsToRedeem) {
      twoStepRedeemOnClick()
    } else {
      goToUrl()
      setIsRedeemed(true)
      handleUmbrellaOfferRedemption && handleUmbrellaOfferRedemption(offerId)
      document.body.classList.remove('has-locked-scrolling')
    }
  }

  const goToUrl = () => {
    const url = `/api/redeem?offerId=${offerId}&index=${index}`
    if (
      redemptionTypeProperties &&
      redemptionTypeProperties.redemptionNewWindow
    ) {
      window.open(url)
    } else {
      window.location = url
    }
  }

  if (restrictToVipOnly && !userHasVipAccess) return null

  return (
    <>
      {instructions && !requiresTwoStepsToRedeem && (
        <RedemptionInstructions
          instructions={instructions}
          bordered={keyFactsPresent}
        />
      )}
      {isUnavailable && (
        <ThankYouMessaging
          isEligibleSubscriber={isEligibleSubscriber}
          borderCurved={!isUmbrellaView}
          message={
            isUmbrella
              ? 'This reward has sold out'
              : 'This offer is now sold out'
          }
        />
      )}
      {!isUnavailable && (
        <RedemptionButton
          ctaText={redemptionCtaText}
          tertiary={isSecondaryExperience}
          onClick={triggerMaxRedemptionConfirmation}
          openInNewWindow={
            redemptionTypeProperties &&
            redemptionTypeProperties.redemptionNewWindow &&
            !requiresTwoStepsToRedeem
          }
          isUmbrella={isUmbrella}
        />
      )}
      {redemptionLimit > 0 && (
        <>
          {umbrellaChildrenOpenInNewPage ? (
            <ConfirmMaxRedemptionModal
              title="Your chosen reward"
              message={
                <div className="ConfirmMaxRedemptionModalCopyInner Center">
                  <p>
                    You&apos;re about to claim{' '}
                    <span>&quot;{headline}&quot;</span>.{' '}
                    {consequentOffers && consequentOffers.length > 0 && (
                      <>
                        <span>
                          Click continue to redeem this month&apos;s reward.
                          Remember, you won&apos;t be able to claim:
                        </span>
                        <ul>
                          {consequentOffers.map((offer, i) => (
                            <li key={`ConsequentOffer--${i}`}>
                              {offer.headline}
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </p>
                </div>
              }
              onAccept={onAcceptClick}
              onDismiss={() => {}}
              modalOpen={confirmMaxRedemptionModalOpen}
              setModalOpen={setConfirmationMaxRedemptionModelOpen}
            />
          ) : (
            <ConfirmMaxRedemptionModal
              title="Your chosen reward"
              message={
                <div className="ConfirmMaxRedemptionModalCopyInner Center">
                  <p>
                    You&apos;re about to claim{' '}
                    <span>&quot;{headline}&quot;</span>.{' '}
                    {consequentOffers && consequentOffers.length > 0 && (
                      <>
                        <span>
                          If you click continue, just remember that you
                          won&apos;t be able to claim:
                        </span>
                        <ul>
                          {consequentOffers.map((offer, i) => (
                            <li key={`ConsequentOffer--${i}`}>
                              {offer.headline}
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </p>
                </div>
              }
              onAccept={onAcceptClick}
              onDismiss={() => {}}
              modalOpen={confirmMaxRedemptionModalOpen}
              setModalOpen={setConfirmationMaxRedemptionModelOpen}
            />
          )}
        </>
      )}
    </>
  )
}

BasicRedemptionExperience.propTypes = {
  offerId: PropTypes.string,
  redemptionInstructions: PropTypes.string,
  redemptionButtonText: PropTypes.string,
  isSecondaryExperience: PropTypes.bool,
  keyFactsPresent: PropTypes.bool,
  isTwoStepRedeem: PropTypes.bool,
  isEligibleSubscriber: PropTypes.bool,
  redemptionTypeProperties: PropTypes.object,
  userHasPreviouslyRedeemed: PropTypes.object,
  redemptionLimit: PropTypes.number,
  redemptionCount: PropTypes.number,
  consequentOffers: PropTypes.array,
  headline: PropTypes.string,
  isUmbrella: PropTypes.any,
  isUmbrellaView: PropTypes.bool,
  umbrellaChildrenOpenInNewPage: PropTypes.any,
  userHasVipAccess: PropTypes.bool,
  restrictToVipOnly: PropTypes.bool,
}

export default BasicRedemptionExperience
