import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'

/**
 * Context to store the umbrella offers that have been redeemed on the client side.
 * This is required to prevent the user from redeeming more offers than they are allowed to.
 */
export const UmbrellaOffersRedemptionContext = createContext()

const UmbrellaOffersRedemptionContextProvider = ({ children }) => {
  const [redeemedUmbrellaOffers, setRedeemedUmbrellaOffers] = useState([])

  const handleUmbrellaOfferRedemption = (offerId) => {
    setRedeemedUmbrellaOffers((prev) => [...prev, offerId])
  }

  return (
    <UmbrellaOffersRedemptionContext.Provider
      value={{ redeemedUmbrellaOffers, handleUmbrellaOfferRedemption }}
    >
      {children}
    </UmbrellaOffersRedemptionContext.Provider>
  )
}

UmbrellaOffersRedemptionContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default UmbrellaOffersRedemptionContextProvider
