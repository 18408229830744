import React, { useContext } from 'react'
import classNames from 'classnames'
import { bool, func, node, oneOf, string } from 'prop-types'

import Icon from '../Icon'
import IconButton from '../IconButton'
import { StatusContext } from '../../Context/StatusContext'

const Notification = ({ children, icon, onDismiss, shadow, status }) => {
  const contextStatus = useContext(StatusContext)
  const derivedStatus = status || contextStatus

  return (
    <div
      className={classNames(
        'Notification',
        derivedStatus && derivedStatus,
        shadow && 'shadow'
      )}
    >
      {icon && (
        <div className="NotificationIcon">
          <Icon type={icon} a11yText="" />
        </div>
      )}
      <div className="NotificationContent">{children}</div>
      {onDismiss && (
        <div className="NotificationDismiss">
          <IconButton
            a11yText="Dismiss"
            icon="close"
            increaseHitArea
            onClick={onDismiss}
            small
          />
        </div>
      )}
    </div>
  )
}

Notification.propTypes = {
  children: node.isRequired,
  icon: string,
  onDismiss: func,
  shadow: bool,
  status: oneOf(['none', 'error', 'notice', 'success', 'warning']),
}

export default Notification
