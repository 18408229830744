import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  useFloating,
  autoUpdate,
  autoPlacement,
  shift,
  offset,
  FloatingFocusManager,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
} from '@floating-ui/react'
import Icon from '../../../Primitive/Icon'

function GSFloatingPanel(props) {
  const { label, isOpen, setIsOpen, isActive, children } = props

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    transform: true,
    middleware: [
      offset(8),
      autoPlacement({
        allowedPlacements: ['bottom-start', 'top-start'],
      }),
      shift(),
    ],
  })

  const click = useClick(context)
  const dismiss = useDismiss(context)
  const role = useRole(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ])

  return (
    <div
      className={classNames(
        'GSFloatingPanel',
        isOpen && 'GSFloatingPanel--open',
        isActive && 'GSFloatingPanel--active'
      )}
    >
      <button
        ref={refs.setReference}
        className="GSFloatingPanelToggle"
        {...getReferenceProps()}
      >
        {label}
        <Icon
          className="GSFloatingPanelToggleChevron"
          type="GSChevron"
          width={8}
          height={8}
          a11yText="Toggle filter"
        />
      </button>
      {isOpen && (
        <FloatingFocusManager context={context} modal={false}>
          <div
            ref={refs.setFloating}
            className="GSFloatingPanelWrapper"
            style={floatingStyles}
            {...getFloatingProps()}
          >
            {children}
          </div>
        </FloatingFocusManager>
      )}
    </div>
  )
}

GSFloatingPanel.propTypes = {
  isOpen: PropTypes.bool,
  isActive: PropTypes.bool,
  setIsOpen: PropTypes.func,
  children: PropTypes.node,
  label: PropTypes.string,
}

export default GSFloatingPanel
