import React from 'react'
import ReactDOM from 'react-dom'
import InlineImageContainer from '../../../site/layout/component/InlineImageContainer'

const debug = require('debug')('offer-images')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.js-inline-image').forEach((appNode) => {
      const props = JSON.parse(appNode.getAttribute('data-props') || '{}')
      ReactDOM.render(<InlineImageContainer {...props} />, appNode)
    })
  })
}
