const svgDimensionsFormatter = (svg) => {
  const svgProps = svg().props
  const { width, height } = svgProps

  if (width && height) return { width, height }

  const viewBox = svg.defaultProps && svg.defaultProps.viewBox

  if (viewBox) {
    const viewBoxSplit = viewBox.split(' ')

    return {
      width: viewBoxSplit[2],
      height: viewBoxSplit[3],
    }
  }

  return {
    width: 0,
    height: 0,
  }
}

export default svgDimensionsFormatter
