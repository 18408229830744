import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Spinner from '../../Primitive/Spinner'

export function GSButton(props) {
  const {
    variant = 'primary',
    onClick,
    href,
    size = 'md',
    children,
    className,
    loading,
    ...rest
  } = props

  const Element = href ? 'a' : 'button'

  return (
    <Element
      className={classNames(
        'GSButton',
        `GSButton--${variant}`,
        `GSButton--${size}`,
        loading && 'GSButton--loading',
        className
      )}
      onClick={onClick}
      href={href}
      {...rest}
    >
      {loading && (
        <div className="GSButtonLoading">
          <Spinner size={16} />
        </div>
      )}
      {children}
    </Element>
  )
}

GSButton.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary', 'outline']),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  href: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  loading: PropTypes.bool,
}

export default GSButton
