import React from 'react'
import classNames from 'classnames'
import { bool, node, oneOf, oneOfType, string } from 'prop-types'
// import sassKeysToArray from '../../../lib/sass-keys-to-array'

// import styles from "./GridWrapper.scss";

// const gutters = sassKeysToArray(styles.gutters)
const gutters = ['half', 'default', 'double']

const GridWrapper = ({
  children,
  gutter,
  reverse,
  flex,
  center,
  className,
}) => (
  <div
    className={classNames(
      'GridWrapper',
      className,
      flex && 'flex',
      center && `center${typeof center === 'string' ? '-' + center : ''}`,
      gutter && `gutter-${gutter}`,
      reverse && `reverse${typeof reverse === 'string' ? '-' + reverse : ''}`
    )}
  >
    {children}
  </div>
)

GridWrapper.propTypes = {
  children: node.isRequired,
  flex: bool,
  center: oneOfType([bool, string]),
  gutter: oneOf(gutters),
  reverse: oneOfType([bool, string]),
  className: string,
}

export default GridWrapper
