import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Carousel from '../Primitive/Carousel'
import InlineImage from '../Primitive/InlineImage'
import Container from '../Primitive/Container'

class InlineImageContainer extends PureComponent {
  render() {
    const { images, carouselShouldAutoplay } = this.props

    const generateSrcSet = (image) => [
      { width: 480, src: image.imgUrl['480w'] },
      { width: 768, src: image.imgUrl['768w'] },
      { width: 960, src: image.imgUrl['960w'] },
      { width: 1024, src: image.imgUrl['1024w'] },
      { width: 1536, src: image.imgUrl['1536w'] },
    ]

    return (
      <div className="InlineImageContainer">
        <Container size="medium" center gutter>
          {images.length === 1 ? (
            <InlineImage
              caption={images[0].caption}
              credits={images[0].credits}
              srcSet={generateSrcSet(images[0])}
              src={images[0].imgUrl['960w']}
              alt={images[0].title}
              link={images[0].link}
              isExternalLink={images[0].externalLink}
            />
          ) : (
            <Carousel
              bordered
              autoplay={carouselShouldAutoplay}
              arrowsColour="altGreyArrows"
            >
              {images.map((image, i) => (
                <InlineImage
                  key={i}
                  srcSet={generateSrcSet(image)}
                  src={image.imgUrl['960w']}
                  sizes={[
                    '(min-width: 1280px) 800px',
                    '(min-width: 768px) calc(75vw - 64px)',
                    'calc(100vw - 24px)',
                  ]}
                  caption={image.caption}
                  credits={image.credits}
                  alt={image.title}
                  link={image.link}
                  isExternalLink={image.externalLink}
                />
              ))}
            </Carousel>
          )}
        </Container>
      </div>
    )
  }
}

InlineImageContainer.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object),
  carouselShouldAutoplay: PropTypes.bool,
  carouselNavigationArrowsColour: PropTypes.string,
}

export default InlineImageContainer
