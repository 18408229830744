import React from 'react'
import PropTypes from 'prop-types'
import Modal from '../../../Primitive/Modal'
import ButtonStandard from '../../../Primitive/ButtonStandard'
import Type from '../../../Primitive/Type'

const ConfirmMaxRedemptionModal = ({
  title,
  message,
  onAccept,
  onDismiss,
  modalOpen,
  setModalOpen,
}) => {
  const onModalClose = () => {
    setModalOpen(false)
    onDismiss()
  }

  const onConfirmationClick = () => {
    setModalOpen(false)
    onAccept()
  }

  const onDismissClick = () => {
    setModalOpen(false)
    onDismiss()
  }
  return (
    <Modal title={title} onClose={onModalClose} isOpen={modalOpen}>
      <div className="ConfirmMaxRedemptionModalContainer">
        <Type as="div" size="base" className="ConfirmMaxRedemptionModalCopy">
          {message}
        </Type>
        <div className="ConfirmMaxRedemptionModalButtons">
          <ButtonStandard secondary onClick={onConfirmationClick}>
            Continue
          </ButtonStandard>
          <ButtonStandard tertiary onClick={onDismissClick}>
            Cancel
          </ButtonStandard>
        </div>
      </div>
    </Modal>
  )
}

ConfirmMaxRedemptionModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onAccept: PropTypes.func,
  onDismiss: PropTypes.func,
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func,
}

export default ConfirmMaxRedemptionModal
