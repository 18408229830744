import React from 'react'
import classNames from 'classnames'
import { bool, node, string } from 'prop-types'

const Prose = ({ children, className, dangerousHtml, inverse }) => {
  if (!children && !dangerousHtml) return null

  return (
    <div
      className={classNames('Prose', inverse && 'inverse', className)}
      {...(dangerousHtml && {
        dangerouslySetInnerHTML: { __html: dangerousHtml },
      })}
    >
      {children}
    </div>
  )
}

Prose.propTypes = {
  children: node,
  className: string,
  dangerousHtml: string,
  inverse: bool,
}

export default Prose
