import React, { PureComponent } from 'react'
import { string } from 'prop-types'

class OffsetAnchor extends PureComponent {
  render() {
    const { identifier } = this.props
    return <div id={identifier} className="OffsetAnchor" />
  }
}

OffsetAnchor.displayName = 'OffsetAnchor'

OffsetAnchor.propTypes = {
  identifier: string,
}

export default OffsetAnchor
