const dataLayerPush = (event, options) => {
  const windowDataLayerPresent = window && window.dataLayer

  if (windowDataLayerPresent) {
    if (options.bookingName) {
      window.dataLayer.bookingName = options.bookingName
    }

    if (!window.dataLayer.event) {
      window.dataLayer.event = [event]
    } else {
      window.dataLayer.event.push(event)
    }
  }
}

export const fireBookingConfirmedEvent = (options) => {
  dataLayerPush('eventBookingConfirmed', options)
}
