import React from 'react'
import useEscapeKey from '../../../hook/useEscapeKey'
import FocusTrap from 'focus-trap-react'
import Icon from '../../Primitive/Icon'
import PropTypes from 'prop-types'

export function GSMembershipPanel(props) {
  const { onOpenChange, isOpen, children } = props
  const focusRef = React.useRef(null)

  useEscapeKey(() => onOpenChange(false))

  React.useEffect(() => {
    if (isOpen) {
      focusRef.current.focus()
    }
  }, [isOpen])

  return (
    <FocusTrap>
      <div className="GSMembershipPanelWrapper">
        <div
          className="GSMembershipPanelOverlay"
          onClick={() => onOpenChange(false)}
          role="presentation"
        />
        <aside className="GSMembershipPanel">
          <div className="GSMembershipPanelHeader">
            <button
              onClick={() => onOpenChange(false)}
              tabIndex={0}
              ref={focusRef}
              aria-label="Close panel"
              className="GSMembershipPanelClose"
            >
              <Icon a11yText="Close" type="Close" width={12} height={12} />
            </button>
          </div>
          <div className="GSMembershipPanelContent">{children}</div>
        </aside>
      </div>
    </FocusTrap>
  )
}

GSMembershipPanel.propTypes = {
  onOpenChange: PropTypes.func,
  isOpen: PropTypes.bool,
  children: PropTypes.node,
}
