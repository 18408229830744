import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { InView } from 'react-intersection-observer'

import ReactMounter from '../Primitive/ReactMounter'
import RedemptionArea from '../RedemptionArea'
import KeyFacts from './component/KeyFacts'
import SubscriptionUpsell from './component/SubscriptionUpsell'
import SubscribeButton from './component/SubscribeButton'
import VipUpgradeUpsell from './component/VipUpgradeUpsell'
import VipUpgradeButton from './component/VipUpgradeButton'
import OffsetAnchor from '../Primitive/OffsetAnchor'
import CustomMessage from './component/CustomMessage/index'
import IneligibleSubscription from './component/IneligibleSubscription'
import FAQsButton from './component/FAQsButton'
import { upgradable } from '../../../widget/lib/access-determiner'
import { canUpgradeDeterminer } from './lib/can-upgrade-determiner'
import { noVipAccessProducts } from '../../../identity/lib/vip-access-determiner'

class RedemptionPanel extends PureComponent {
  constructor() {
    super()
    this.setShortcutVisibility = this.setShortcutVisibility.bind(this)
    this.state = {
      showShortcutButton: true,
    }
  }

  setShortcutVisibility(isVisible) {
    this.setState({ showShortcutButton: isVisible })
  }

  render() {
    const {
      offerId,
      redemptionStatus,
      redemptionLimit,
      redemptionCount,
      headline,
      keyFacts,
      formData,
      redemptionExperiences,
      nonSubscriberCtaText,
      isTwoStepRedeem,
      userHasPreviouslyRedeemed,
      shouldShowKeyFacts,
      shouldShowSubscriptionUpsell,
      shouldShowSubscribeButton,
      shouldShowCustomMessage,
      shouldShowUmbrellaChildInstructions,
      umbrellaChildInstructions,
      isSubscriber,
      isEligibleSubscriber,
      hasExtraAccess,
      hasPuzzlesAccess,
      hasWineAccess,
      hasVipAccess,
      redemptionMessaging,
      isLoggedIn,
      isRegistrant,
      shouldShowLoginLink,
      shouldShowTerms,
      termsAndConditions,
      addToCalendarEnabled,
      addToCalendarStartDate,
      userData,
      unavailableMessaging,
      currentUrl,
      offerCategory,
      stripePublicKey,
      isModalOpen,
      stripeJsVersion,
      isVipOffer,
      consequentOffers,
      isUmbrella,
      umbrellaChildrenOpenInNewPage,
      userHasRedeemedPrimary,
      userHasRedeemedSecondary,
      userHasGourmet,
    } = this.props

    const redemptionAreaProps = {
      offerId,
      redemptionStatus,
      redemptionLimit,
      redemptionCount,
      keyFacts,
      headline,
      isSubscriber,
      isEligibleSubscriber,
      hasExtraAccess,
      hasPuzzlesAccess,
      hasWineAccess,
      hasVipAccess,
      redemptionMessaging,
      isLoggedIn,
      isRegistrant,
      keyFactsPresent: !!keyFacts,
      redemptionExperiences,
      isTwoStepRedeem,
      addToCalendarEnabled,
      addToCalendarStartDate,
      userHasPreviouslyRedeemed,
      shouldShowSubscribeButton,
      shouldShowSubscriptionUpsell,
      shouldShowCustomMessage,
      shouldShowKeyFacts,
      shouldShowLoginLink,
      shouldShowTerms,
      shouldShowUmbrellaChildInstructions,
      umbrellaChildInstructions,
      termsAndConditions,
      formData,
      userData,
      unavailableMessaging,
      currentUrl,
      offerCategory,
      stripePublicKey,
      showShortcutButton: this.state.showShortcutButton,
      isModalOpen,
      stripeJsVersion,
      isVipOffer,
      consequentOffers,
      isUmbrella,
      umbrellaChildrenOpenInNewPage,
      userHasRedeemedPrimary,
      userHasRedeemedSecondary,
      userHasGourmet,
    }

    const userSubscriptionType = userData && userData.subscriptionType
    const normalisedSubscriptionType = Array.isArray(userSubscriptionType)
      ? userSubscriptionType[0]
      : userSubscriptionType
    const isUpgradable = upgradable.includes(normalisedSubscriptionType)
    const vipIneligibleSubscriptionType = noVipAccessProducts.includes(
      normalisedSubscriptionType
    )
    const canUpgrade = canUpgradeDeterminer(
      normalisedSubscriptionType,
      (userData && userData.subscriptionTenure) || null
    )

    return (
      <ReactMounter
        className="js-redemption-panel"
        clientSideProps={redemptionAreaProps}
      >
        <InView
          as="div"
          className="RedemptionPanel"
          onChange={(inView) => this.setShortcutVisibility(!inView)}
          rootMargin="-110px 0px -30px 0px"
        >
          <OffsetAnchor identifier="RedemptionPanel" />
          <div className="RedemptionPanelInner">
            {!shouldShowCustomMessage &&
              shouldShowKeyFacts &&
              (!isVipOffer || (isVipOffer && hasVipAccess)) && (
                <div className="RedemptionPanelKeyFacts">
                  <KeyFacts items={keyFacts} />
                </div>
              )}
            {shouldShowSubscriptionUpsell && !isVipOffer && (
              <SubscriptionUpsell
                hasExtraAccess={hasExtraAccess}
                isSubscriber={isSubscriber}
                isUpgradable={isUpgradable}
              />
            )}
            {shouldShowSubscribeButton && !isVipOffer && (
              <SubscribeButton
                hasExtraAccess={hasExtraAccess}
                isSubscriber={isSubscriber}
                ctaText={nonSubscriberCtaText}
                currentUrl={currentUrl}
                isUpgradable={isUpgradable}
              />
            )}

            {!hasVipAccess && isVipOffer && (
              <>
                {vipIneligibleSubscriptionType ? (
                  <>
                    <IneligibleSubscription hasExtraAccess={hasExtraAccess} />
                    <FAQsButton />
                  </>
                ) : (
                  <>
                    <VipUpgradeUpsell
                      isLoggedIn={isLoggedIn}
                      hasExtraAccess={hasExtraAccess}
                      isSubscriber={isSubscriber}
                      isRegistrant={isRegistrant}
                      subscriptionTenure={
                        (userData && userData.subscriptionTenure) || null
                      }
                      subscriptionType={normalisedSubscriptionType}
                      canUpgrade={canUpgrade}
                    />
                    {isUpgradable ? (
                      <VipUpgradeButton
                        isSubscriber={isSubscriber}
                        isRegistrant={isRegistrant}
                        currentUrl={currentUrl}
                        canUpgrade={canUpgrade}
                      />
                    ) : (
                      <FAQsButton />
                    )}
                  </>
                )}
              </>
            )}

            {shouldShowCustomMessage && !isVipOffer && (
              <CustomMessage redemptionMessaging={redemptionMessaging} />
            )}
            <RedemptionArea {...redemptionAreaProps} />
          </div>
        </InView>
      </ReactMounter>
    )
  }
}

RedemptionPanel.propTypes = {
  shouldShowSubscribeButton: PropTypes.bool,
  isTwoStepRedeem: PropTypes.bool,
  userHasPreviouslyRedeemed: PropTypes.bool,
  shouldShowTerms: PropTypes.bool,
  shouldShowLoginLink: PropTypes.bool,
  addToCalendarEnabled: PropTypes.bool,
  addToCalendarStartDate: PropTypes.instanceOf(Date),
  shouldShowKeyFacts: PropTypes.bool,
  isSubscriber: PropTypes.bool,
  isEligibleSubscriber: PropTypes.bool,
  hasExtraAccess: PropTypes.bool,
  hasPuzzlesAccess: PropTypes.bool,
  hasWineAccess: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  isRegistrant: PropTypes.bool,
  shouldShowSubscriptionUpsell: PropTypes.bool,
  shouldShowCustomMessage: PropTypes.bool,
  keyFacts: PropTypes.arrayOf(PropTypes.object),
  formData: PropTypes.object,
  nonSubscriberCtaText: PropTypes.string,
  termsAndConditions: PropTypes.string,
  currentUrl: PropTypes.string,
  offerCategory: PropTypes.string,
  offerId: PropTypes.string,
  unavailableMessaging: PropTypes.string,
  redemptionExperiences: PropTypes.array,
  redemptionMessaging: PropTypes.object,
  userData: PropTypes.object,
  stripePublicKey: PropTypes.string,
  isModalOpen: PropTypes.bool,
  stripeJsVersion: PropTypes.string,
  headline: PropTypes.string,
  redemptionStatus: PropTypes.string,
  redemptionLimit: PropTypes.number,
  redemptionCount: PropTypes.number,
  isVipOffer: PropTypes.bool,
  hasVipAccess: PropTypes.bool,
  shouldShowUmbrellaChildInstructions: PropTypes.bool,
  umbrellaChildInstructions: PropTypes.string,
  consequentOffers: PropTypes.array,
  isUmbrella: PropTypes.any,
  umbrellaChildrenOpenInNewPage: PropTypes.any,
  userHasRedeemedPrimary: PropTypes.bool,
  userHasRedeemedSecondary: PropTypes.bool,
  userHasGourmet: PropTypes.bool,
}

export default RedemptionPanel
