import React from 'react'
import ReactDOM from 'react-dom'
import WatchAgain from '../../../site/layout/component/WatchAgain'

const debug = require('debug')('offer-images')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.js-watch-again').forEach((appNode) => {
      const props = JSON.parse(appNode.getAttribute('data-props') || '{}')
      ReactDOM.render(<WatchAgain {...props} />, appNode)
    })
  })
}
