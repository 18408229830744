import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Icon from '../../../Primitive/Icon'

const ThankYouMessaging = ({
  message,
  bordered,
  isSubscriber,
  borderCurved = true,
}) => {
  return (
    <div
      className={classNames(
        'ThankYouMessaging',
        bordered && 'ThankYouMessagingBordered',
        isSubscriber && 'ThankYouMessagingSubscriber'
      )}
    >
      {borderCurved && (
        <Icon
          className="ThankYouMessagingArrow"
          type="StatusArrowSmall"
          width={32}
          height={16}
        />
      )}
      {typeof message === 'object' && message !== null ? (
        <div className="Type TypeThankYouMessaging ThankYouMessagingMessage">
          {message}
        </div>
      ) : (
        <div
          className="Type TypeThankYouMessaging ThankYouMessagingMessage"
          dangerouslySetInnerHTML={{
            __html: message,
          }}
        />
      )}
    </div>
  )
}

ThankYouMessaging.propTypes = {
  bordered: PropTypes.bool,
  isSubscriber: PropTypes.bool,
  message: PropTypes.string,
  borderCurved: PropTypes.bool,
}

export default ThankYouMessaging
