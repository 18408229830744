function formatAddress(address) {
  if (!address) return null

  //only show the first line of the address
  const parts = [
    address.building,
    address.street,
    address.locality,
    address.town,
    // address.county,
    // address.postcode,
    // address.country,
  ]

  const formattedParts = parts.filter((part) => !!part)
  return formattedParts.join(', ')
}

export default formatAddress
