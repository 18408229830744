import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Icon from '../../../Primitive/Icon'

function GSFilterSearch(props) {
  const { value, setValue, className } = props

  const handleClear = () => {
    setValue('')
  }

  return (
    <div className={classNames('GSFilterSearch', className)}>
      <label htmlFor="GSFilterSearch" className="GSFilterSearchLabel">
        Search
      </label>
      <div className="GSFilterSearchInputContainer">
        <Icon
          type="GSSearch"
          a11yText="Search"
          className="GSFilterSearchIcon"
          width={13}
          height={13}
        />
        <input
          id="GSFilterSearch"
          type="text"
          placeholder="Search"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
      <div className="GSFilterSearchActions">
        {value.length > 1 && (
          <button onClick={handleClear} className="GSFilterSearchClear">
            <Icon type="Cross" a11yText="Clear" width={12} height={12} />
          </button>
        )}
      </div>
    </div>
  )
}

GSFilterSearch.propTypes = {
  className: PropTypes.string,
  setValue: PropTypes.func,
  value: PropTypes.string,
}

export default GSFilterSearch
