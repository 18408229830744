import React from 'react'
import { array } from 'prop-types'

import Type from '../../../Primitive/Type'

const KeyFacts = ({ items }) =>
  items.map((item, i) => (
    <div key={i} className="KeyFact">
      <Type className="KeyFactTitle" size="KeyFactTitle">
        {item.key}
      </Type>
      <div
        className="Type TypeKeyFactDescription KeyFactDescription"
        dangerouslySetInnerHTML={{ __html: item.value }}
      />
    </div>
  ))

KeyFacts.propTypes = {
  items: array,
}

export default KeyFacts
