import React, { PureComponent } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Type from '../Type'
import SummaryStatus from './components/SummaryStatus'
import VipLogo from '../VipLogo'

class Summary extends PureComponent {
  render() {
    const { modifierClass, offer, winners, hideStatus } = this.props
    const {
      when,
      sell,
      shortHeadline,
      category,
      showCategory,
      __fullUrlPath,
      status,
      image,
      cost,
      location,
      isVipOffer,
      redemptionExperiences,
      __userHasPreviouslyRedeemed,
    } = offer

    const Wrapper = ({ children }) =>
      __fullUrlPath ? (
        <a
          className={classNames('SummaryWrapper', modifierClass)}
          href={__fullUrlPath}
        >
          {children}
        </a>
      ) : (
        <div className={classNames('SummaryWrapper', modifierClass)}>
          {children}
        </div>
      )

    const redemptionTypesToShowIfUserHasRedeemed = ['squadup', 'competition']
    const showUserHasRedeemed =
      __userHasPreviouslyRedeemed &&
      redemptionTypesToShowIfUserHasRedeemed.includes(
        redemptionExperiences && redemptionExperiences[0].redemptionType
      )

    const pushImageDown =
      showUserHasRedeemed || (!hideStatus && status && status !== 'Available')

    return (
      <Wrapper>
        <div className="SummaryImage">
          <SummaryStatus
            category={category}
            status={status}
            __userHasPreviouslyRedeemed={showUserHasRedeemed}
            hideStatus={hideStatus}
          />
          <img
            className={pushImageDown ? 'SummaryImageTranslated' : null}
            src={image.url}
            alt=""
          />
        </div>
        <div className="SummaryDetailsTop" />
        {isVipOffer && (
          <div
            className={classNames(
              'SummaryIconWrapper',
              isVipOffer && 'WithVipLogo'
            )}
          >
            {isVipOffer && <VipLogo withBorders />}
          </div>
        )}
        <div className="SummaryDetails">
          {category && showCategory && (
            <Type className="SummaryCategory" size="SummaryCategory">
              {category}
            </Type>
          )}
          <Type as="h3" className="SummaryTitle" size="SummaryTitle">
            {shortHeadline}
          </Type>
          {sell && (
            <div
              className="SummaryDescription Type TypeSummaryDescription"
              dangerouslySetInnerHTML={{ __html: sell }}
            />
          )}
          <div className="SummaryExtraDetails">
            {when && (
              <Type as="div" size="SummarySmall">
                {when}
              </Type>
            )}
            {(cost || location) && (
              <Type as="div" size="SummarySmallItalic">
                {cost && `${cost} – `}
                {location && `${location}`}
              </Type>
            )}
            {winners && (
              <Type as="div" size="SummaryDescription">
                <span>Congratulations, </span>
                {winners.map((winner, index) => {
                  const isLast = winners.length === index + 1
                  return `${winner}${isLast ? '' : ', '}`
                })}
              </Type>
            )}
          </div>
        </div>
      </Wrapper>
    )
  }
}

Summary.propTypes = {
  image: PropTypes.object,
  category: PropTypes.string,
  showCategory: PropTypes.bool,
  shortHeadline: PropTypes.string,
  summaryDesc: PropTypes.string,
  summaryDate: PropTypes.string,
  __fullUrlPath: PropTypes.string,
  status: PropTypes.string,
  hideStatus: PropTypes.bool,
  __userHasPreviouslyRedeemed: PropTypes.bool,
  additionalWrapperClass: PropTypes.string,
  location: PropTypes.string,
  cost: PropTypes.string,
  offer: PropTypes.object,
  modifierClass: PropTypes.string,
  winners: PropTypes.array,
}

export default Summary
