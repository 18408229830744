import React from 'react'
import { bool, node, oneOf, string } from 'prop-types'
import classNames from 'classnames'

// import styles from "./Type.scss";

import Element from '../Element'

export const sizes = [
  'Footer',
  'FooterSmall',
  'heading1',
  'heading2',
  'heading3',
  'heading4',
  'heading5',
  'base',
  'small',
]

export const colors = ['grey40', 'purple', 'white', 'yellow']

export const weights = ['bold']

const Type = ({
  as = 'div',
  children,
  className,
  color,
  size = 'base',
  tight,
  weight,
}) => (
  <Element
    as={as}
    className={classNames(
      'Type',
      size && `Type${size}`,
      tight && 'tight',
      color && `color-${color}`,
      weight && `weight-${weight}`,
      className
    )}
  >
    {children}
  </Element>
)

Type.displayName = 'Type'

Type.propTypes = {
  as: string,
  children: node.isRequired,
  className: string,
  color: oneOf(colors),
  size: string,
  tight: bool,
  weight: oneOf(weights),
}

export default Type
