import React from 'react'
import { node } from 'prop-types'

const VisuallyHidden = ({ children }) => (
  <span className="VisuallyHidden">{children}</span>
)

VisuallyHidden.propTypes = {
  children: node.isRequired,
}

export default VisuallyHidden
