function formatSuggestion(restaurant) {
  return {
    type: 'restaurant',
    name: restaurant.name,
    location: [restaurant.address.town, restaurant.address.street]
      .filter(Boolean)
      .join(', '),
    ...(restaurant.primaryImage && {
      image: restaurant.primaryImage.href,
    }),
    ...(restaurant.geo && {
      geo: {
        lat: restaurant.geo.lat,
        lng: restaurant.geo.long,
      },
    }),
  }
}

export default formatSuggestion
