import React from 'react'
import querystring from 'querystring'
import PropTypes from 'prop-types'
import SelectControl from '../SelectControl'
import Type from '../Type'

const SearchSorter = ({ chosenSort }) => {
  const onSearchChange = (e) => {
    const currentQueryData = querystring.parse(
      window.location.search.replace('?', '')
    )
    currentQueryData.sort = e.target.value
    window.location = `${window.location.origin}${
      window.location.pathname
    }?${querystring.stringify(currentQueryData)}`
  }
  return (
    <Type size="SearchResults" className="SearchResultsControls">
      <label className="SearchResultsLabel">Sort by:</label>
      <div className="SearchResultsSelectControl">
        <SelectControl
          name="SearchResultsOrdering"
          className="SearchResultsOrdering"
          onChange={onSearchChange}
        >
          <option value="recommended">Recommended</option>
          <option value="ending" selected={chosenSort === 'ending'}>
            Closing Soonest
          </option>
          <option value="newest" selected={chosenSort === 'newest'}>
            Newest Added
          </option>
        </SelectControl>
      </div>
    </Type>
  )
}

SearchSorter.propTypes = {
  chosenSort: PropTypes.string,
}

export default SearchSorter
