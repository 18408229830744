import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'

import RedemptionButton from '../../RedemptionButton'
import ThankYouMessaging from '../../ThankYouMessaging'
import ConfirmMaxRedemptionModal from '../../ConfirmMaxRedemptionModal'

import FormBuilder from '../../../../FormBuilder/FormBuilder'
import AgreeToTermsCheckbox from '../../../../AgreeToTermsCheckbox'

import Modal from '../../../../Primitive/Modal'
import Grid from '../../../../Primitive/Grid'
import { UmbrellaOffersRedemptionContext } from '../../../../Context/UmbrellaOffersRedemptionContext'

const formatFormDataToObject = (formData) => {
  const data = {}
  for (const [name, value] of formData) {
    if (data[name]) {
      data[name] += `,${value}`
    } else {
      data[name] = value
    }
  }
  return data
}

const CompetitionRedemptionExperience = ({
  isEligibleSubscriber,
  redemptionButtonText,
  redemptionTypeProperties,
  userHasPreviouslyRedeemed,
  formData,
  offerId,
  termsAndConditions,
  redemptionLimit,
  headline,
  consequentOffers,
  umbrellaChildrenOpenInNewPage,
  isUmbrella,
  isUmbrellaView,
  userHasVipAccess,
  restrictToVipOnly,
}) => {
  const umbrellaOfferContext = useContext(UmbrellaOffersRedemptionContext)
  const { handleUmbrellaOfferRedemption } = umbrellaOfferContext || {}

  if (!isEligibleSubscriber) return null
  const [formAnswers, setFormAnswers] = useState(
    formData ? formData.prepopulateData : {}
  )
  const [showThankYou, setShowThankYou] = useState(userHasPreviouslyRedeemed)
  const [modalOpen, setModalOpen] = useState(false)
  const [confirmMaxRedemptionModalOpen, setConfirmationMaxRedemptionModelOpen] =
    useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [formErrors, setFormErrors] = useState({})

  const onFormSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    const body = formatFormDataToObject(new FormData(e.target))
    setFormAnswers(body)
    const res = await fetch(
      `/api/form-builder/${redemptionTypeProperties.redemptionCompetition}?offerId=${offerId}`,
      {
        headers: { 'content-type': 'application/json' },
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(body),
      }
    )
    const response = await res.json()
    setIsLoading(false)
    if (response.error) {
      setFormErrors(response.error)
    }
    if (response.success) {
      setModalOpen(false)
      setShowThankYou(true)
      document.body.classList.remove('has-locked-scrolling')
      handleUmbrellaOfferRedemption && handleUmbrellaOfferRedemption(offerId)
    }
  }

  const triggerMaxRedemptionConfirmation = () => {
    if (redemptionLimit > 0) {
      setConfirmationMaxRedemptionModelOpen(true)
    } else {
      onAcceptClick()
    }
  }

  const onAcceptClick = () => {
    setModalOpen(true)
  }

  const onModalClose = () => {
    setModalOpen(false)
    setFormAnswers(formData.prepopulateData)
    setFormErrors({})
  }

  if (restrictToVipOnly && !userHasVipAccess) return null

  return (
    <>
      {!showThankYou && (
        <>
          <RedemptionButton
            isUmbrella={isUmbrella}
            ctaText={redemptionButtonText}
            onClick={triggerMaxRedemptionConfirmation}
          />
          <Modal
            title={redemptionTypeProperties.modalTitle || 'Enter to win'}
            onClose={onModalClose}
            isOpen={modalOpen}
          >
            <FormBuilder
              questions={formData.questions}
              data={formAnswers}
              onSubmit={onFormSubmit}
              isLoading={isLoading}
              errors={formErrors}
            >
              {termsAndConditions && (
                <Grid.Item trailingGap="default">
                  <AgreeToTermsCheckbox terms={termsAndConditions} />
                </Grid.Item>
              )}
            </FormBuilder>
          </Modal>
        </>
      )}
      {redemptionLimit > 0 && (
        <>
          {umbrellaChildrenOpenInNewPage ? (
            <ConfirmMaxRedemptionModal
              title="Your chosen reward"
              message={
                <div className="ConfirmMaxRedemptionModalCopyInner Center">
                  <p>
                    You&apos;re about to claim{' '}
                    <span>&quot;{headline}&quot;</span>.{' '}
                    {consequentOffers && consequentOffers.length > 0 && (
                      <>
                        <span>
                          Click continue to redeem this month&apos;s reward.
                          Remember, you won&apos;t be able to claim:
                        </span>
                        <ul>
                          {consequentOffers.map((offer, i) => (
                            <li key={`ConsequentOffer--${i}`}>
                              {offer.headline}
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </p>
                </div>
              }
              onAccept={onAcceptClick}
              onDismiss={() => {}}
              modalOpen={confirmMaxRedemptionModalOpen}
              setModalOpen={setConfirmationMaxRedemptionModelOpen}
            />
          ) : (
            <ConfirmMaxRedemptionModal
              title="Your chosen reward"
              message={
                <div className="ConfirmMaxRedemptionModalCopyInner Center">
                  <p>
                    You&apos;re about to claim{' '}
                    <span>&quot;{headline}&quot;</span>.{' '}
                    {consequentOffers && consequentOffers.length > 0 && (
                      <>
                        <span>
                          If you click continue, just remember that you
                          won&apos;t be able to claim:
                        </span>
                        <ul>
                          {consequentOffers.map((offer, i) => (
                            <li key={`ConsequentOffer--${i}`}>
                              {offer.headline}
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </p>
                </div>
              }
              onAccept={onAcceptClick}
              onDismiss={() => {}}
              modalOpen={confirmMaxRedemptionModalOpen}
              setModalOpen={setConfirmationMaxRedemptionModelOpen}
            />
          )}
        </>
      )}
      {showThankYou && (
        <ThankYouMessaging
          isEligibleSubscriber={isEligibleSubscriber}
          message={redemptionTypeProperties.thankYouMessage}
          bordered
          borderCurved={!isUmbrellaView}
        />
      )}
    </>
  )
}

CompetitionRedemptionExperience.propTypes = {
  isEligibleSubscriber: PropTypes.bool,
  addToCalendarEnabled: PropTypes.bool,
  offerId: PropTypes.string,
  redemptionButtonText: PropTypes.string,
  termsAndConditions: PropTypes.string,
  redemptionTypeProperties: PropTypes.object,
  formData: PropTypes.object,
  userHasPreviouslyRedeemed: PropTypes.bool,
  addToCalendarStartDate: PropTypes.instanceOf(Date),
  redemptionLimit: PropTypes.number,
  consequentOffers: PropTypes.any,
  headline: PropTypes.string,
  umbrellaChildrenOpenInNewPage: PropTypes.any,
  isUmbrella: PropTypes.bool,
  isUmbrellaView: PropTypes.bool,
  userHasVipAccess: PropTypes.bool,
  restrictToVipOnly: PropTypes.bool,
}

export default CompetitionRedemptionExperience
