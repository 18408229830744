import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ButtonStandard from '../../../Primitive/ButtonStandard'

const RedemptionButton = ({
  tertiary,
  ctaText,
  openInNewWindow,
  href,
  onClick,
  isUmbrella,
}) => (
  <div className="RedemptionButton">
    <ButtonStandard
      className={classNames(
        'RedemptionButtonButton',
        isUmbrella ? 'RedemptionButtonUmbrella' : ''
      )}
      secondary={!tertiary}
      tertiary={tertiary}
      block
      clipped
      onClick={onClick}
      href={href}
      target={openInNewWindow ? '_blank' : ''}
    >
      {ctaText}
    </ButtonStandard>
  </div>
)

RedemptionButton.propTypes = {
  tertiary: PropTypes.bool,
  ctaText: PropTypes.string,
  openInNewWindow: PropTypes.bool,
  href: PropTypes.string,
  onClick: PropTypes.func,
  isUmbrella: PropTypes.bool,
}

export default RedemptionButton
