import React, { useEffect, useState, useRef, useContext } from 'react'

import moment from 'moment'
import PropTypes from 'prop-types'
import FullPrice from './FullPrice'
import Confirmation from './Confirmation'
import Modal from '../../../../Primitive/Modal'
import AddToCalendar from '../../AddToCalendar'
import RedemptionButton from '../../RedemptionButton'
import ThankYouMessaging from '../../ThankYouMessaging'
import FormBuilder from '../../../../FormBuilder/FormBuilder'
import { fireBookingConfirmedEvent } from '../../../../../../../../site/lib/data-layer-events'
import ConfirmMaxRedemptionModal from '../../ConfirmMaxRedemptionModal'
import { UmbrellaOffersRedemptionContext } from '../../../../Context/UmbrellaOffersRedemptionContext'

const formatFormDataToObject = (formData) => {
  const data = {}
  for (const [name, value] of formData) {
    if (data[name]) {
      data[name] += `,${value}`
    } else {
      data[name] = value
    }
  }
  return data
}

const SquadUpRedemptionExperience = ({
  offerId,
  headline,
  userData,
  isSubscriber,
  isEligibleSubscriber,
  isRegistrant,
  redemptionButtonText,
  redemptionTypeProperties,
  addToCalendarEnabled,
  addToCalendarStartDate,
  isModalOpen = false,
  stripePublicKey,
  stripeJsVersion,
  formData,
  redemptionLimit,
  consequentOffers,
  umbrellaChildrenOpenInNewPage,
  isUmbrella,
  isUmbrellaView,
  isSecondaryExperience,
  userHasRedeemedPrimary,
  userHasRedeemedSecondary,
  userHasVipAccess,
  restrictToVipOnly,
}) => {
  // Cannot use the "useState" hook here as it forcibly reloads the DOM reseting all the modal states (restarting the SquadUp modal journey).
  let primaryOrderSuccessful = useRef(false)
  let secondaryOrderSuccessful = useRef(false)

  const index = isSecondaryExperience ? 1 : 0

  const umbrellaOfferContext = useContext(UmbrellaOffersRedemptionContext)
  const { handleUmbrellaOfferRedemption, redeemedUmbrellaOffers = [] } =
    umbrellaOfferContext || {}

  const [modalOpen, setModalOpen] = useState(false)
  const [modalHasBeenAutoOpened, setModalHasBeenAutoOpened] = useState(false)
  const [showThankYou, setShowThankYou] = useState(
    isSecondaryExperience
      ? userHasRedeemedSecondary || redeemedUmbrellaOffers.includes(offerId)
      : userHasRedeemedPrimary || redeemedUmbrellaOffers.includes(offerId)
  )

  const [formAnswers, setFormAnswers] = useState(
    formData ? formData.prepopulateData : {}
  )
  const [formErrors, setFormErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showPostEventBookingForm, setShowPostEventBookingForm] =
    useState(false)
  const [confirmMaxRedemptionModalOpen, setConfirmationMaxRedemptionModelOpen] =
    useState(false)

  const postEventBookingFormId = redemptionTypeProperties.postEventBooking

  const handleOrderSuccess = async (e) => {
    e.preventDefault()
    postEventBookingFormId ? setShowPostEventBookingForm(true) : handleSubmit(e)
  }

  const handleSubmit = async (e) => {
    if (e) e.preventDefault()
    let body = {}
    let apiUrl = `/api/redeem?offerId=${offerId}&index=${index}`

    if (postEventBookingFormId) {
      setIsLoading(true)

      body = formatFormDataToObject(new FormData(e.target))
      apiUrl = `/api/form-builder/${postEventBookingFormId}?offerId=${offerId}&type=squadup`

      setFormAnswers(body)
    }

    const res = await fetch(apiUrl, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(body),
      headers: { 'content-type': 'application/json' },
    })
    const response = await res.json()

    if ((response && response.success) || res.status === 200) {
      if (isSecondaryExperience) {
        secondaryOrderSuccessful.current = true
      } else {
        primaryOrderSuccessful.current = true
      }

      fireBookingConfirmedEvent({ bookingName: headline })

      if (postEventBookingFormId) {
        setIsLoading(false)
        setShowConfirmation(true)
      }

      if (!modalOpen) setShowThankYou(true)
    }

    if ((response && response.error) || res.status === 400) {
      setFormErrors(response.error || response)
      if (postEventBookingFormId) setIsLoading(false)
    }
  }

  const openModal = () => {
    setModalOpen(true)
  }

  const handleNonSubscriberRedeem = () => {
    if (isRegistrant || (isSubscriber && !isEligibleSubscriber)) {
      return openModal()
    }
    const redirectUrl = `${window.location.href}?mopen=true`
    window.location = `https://secure.telegraph.co.uk/customer/secure/register/?ICID=subs-offers_rewards_onsite-asset_generic_telegraphextra&redirectTo=${encodeURIComponent(
      redirectUrl
    )}`
  }

  const onModalOpen = () => {
    const config = generateSquadUpEmbed()

    // create config first
    const configScript = document.createElement('script')
    configScript.id = 'squadup-config'
    configScript.text = config
    document.head.appendChild(configScript)

    // create SquadUp script. MUST load after the config is in the DOM, otherwise it won't load.
    const squadUpScript = document.createElement('script')
    squadUpScript.id = 'squadup-script'
    squadUpScript.src = 'https://embed.squadup.com/main-v2.min.js'
    squadUpScript.async = true
    document.head.appendChild(squadUpScript)

    document.addEventListener('orderSuccessful', handleOrderSuccess)
  }

  const onModalClose = () => {
    setModalOpen(false)

    const squadUpScript = document.getElementById('squadup-script')
    const squadUpConfigScript = document.getElementById('squadup-config')

    if (squadUpScript && squadUpScript.parentNode) {
      squadUpScript.parentNode.removeChild(squadUpScript)
    }

    if (squadUpConfigScript && squadUpConfigScript.parentNode) {
      squadUpConfigScript.parentNode.removeChild(squadUpConfigScript)
    }

    if (isSecondaryExperience) {
      if (secondaryOrderSuccessful.current) {
        setShowThankYou(true)
        handleUmbrellaOfferRedemption && handleUmbrellaOfferRedemption(offerId)
      }
    } else {
      if (primaryOrderSuccessful.current) {
        setShowThankYou(true)
        handleUmbrellaOfferRedemption && handleUmbrellaOfferRedemption(offerId)
      }
    }
  }

  const generateSquadUpEmbed = () => {
    const config = {
      root: 'squadup-checkout',
      brandingPosition: 'none',
      textTicketEnabled: false,
      ticketGuardianEnabled: false,
      confirmationText: redemptionTypeProperties.modalConfirmationText
        ? redemptionTypeProperties.modalConfirmationText
        : undefined,
      eventId: isEligibleSubscriber
        ? redemptionTypeProperties.eventId
        : redemptionTypeProperties.secondaryEventId,
      customMessages: {
        'eventDetail.checkoutButtonText':
          redemptionTypeProperties.modalEvenDetailCheckoutCtaText
            ? redemptionTypeProperties.modalEvenDetailCheckoutCtaText
            : 'Continue',
      },
    }

    if (stripePublicKey) {
      config.stripePublicKey = stripePublicKey
    }

    if (stripeJsVersion) {
      config.stripeJsVersion = stripeJsVersion
    }

    if (userData) {
      const { email, firstName, lastName } = userData
      config.userRegDefaults = { firstName, lastName, email }
    }

    if (redemptionTypeProperties.freeRegistrationCtaText) {
      config.checkoutButtonText =
        redemptionTypeProperties.freeRegistrationCtaText
    }

    const embed = `squadup = ${JSON.stringify(config)}`
    return embed
  }

  useEffect(() => {
    if (isModalOpen && !modalHasBeenAutoOpened && isRegistrant) {
      // this is crap but a necessity
      setTimeout(() => {
        openModal()
      }, 1000)
      setModalHasBeenAutoOpened(true)
    }

    return () => {
      document.removeEventListener('orderSuccessful', handleOrderSuccess)
    }
  })

  const thankYouMessage = `${redemptionTypeProperties.thankYouMessage}${
    addToCalendarEnabled
      ? `<br /><p>Event starts ${moment(addToCalendarStartDate).fromNow()}</p>`
      : ''
  }`

  const triggerMaxRedemptionConfirmation = () => {
    const hasPreviouslyRedeemed = isSecondaryExperience
      ? userHasRedeemedSecondary
      : userHasRedeemedPrimary

    if (redemptionLimit > 0 && !hasPreviouslyRedeemed) {
      setConfirmationMaxRedemptionModelOpen(true)
    } else {
      openModal()
    }
  }

  const onAcceptClick = () => {
    openModal()
  }

  if (restrictToVipOnly && !userHasVipAccess) return null

  return (
    <>
      {!showThankYou && (
        <>
          {isEligibleSubscriber && (
            <RedemptionButton
              isUmbrella={isUmbrella}
              ctaText={redemptionButtonText || 'Click to Redeem'}
              onClick={triggerMaxRedemptionConfirmation}
            />
          )}
          {!isEligibleSubscriber &&
            redemptionTypeProperties.fullPrice &&
            redemptionTypeProperties.secondaryEventId && (
              <FullPrice
                cost={redemptionTypeProperties.fullPrice}
                onClick={handleNonSubscriberRedeem}
              />
            )}
          {redemptionLimit > 0 && (
            <>
              {umbrellaChildrenOpenInNewPage ? (
                <ConfirmMaxRedemptionModal
                  title="Your chosen reward"
                  message={
                    <div className="ConfirmMaxRedemptionModalCopyInner Center">
                      <p>
                        You&apos;re about to claim{' '}
                        <span>&quot;{headline}&quot;</span>.{' '}
                        {consequentOffers && consequentOffers.length > 0 && (
                          <>
                            <span>
                              Click continue to redeem this month&apos;s reward.
                              Remember, you won&apos;t be able to claim:
                            </span>
                            <ul>
                              {consequentOffers.map((offer, i) => (
                                <li key={`ConsequentOffer--${i}`}>
                                  {offer.headline}
                                </li>
                              ))}
                            </ul>
                          </>
                        )}
                      </p>
                    </div>
                  }
                  onAccept={onAcceptClick}
                  onDismiss={() => {}}
                  modalOpen={confirmMaxRedemptionModalOpen}
                  setModalOpen={setConfirmationMaxRedemptionModelOpen}
                />
              ) : (
                <ConfirmMaxRedemptionModal
                  title="Your chosen reward"
                  message={
                    <div className="ConfirmMaxRedemptionModalCopyInner Center">
                      <p>
                        You&apos;re about to claim{' '}
                        <span>&quot;{headline}&quot;</span>.{' '}
                        {consequentOffers && consequentOffers.length > 0 && (
                          <>
                            <span>
                              If you click continue, just remember that you
                              won&apos;t be able to claim:
                            </span>
                            <ul>
                              {consequentOffers.map((offer, i) => (
                                <li key={`ConsequentOffer--${i}`}>
                                  {offer.headline}
                                </li>
                              ))}
                            </ul>
                          </>
                        )}
                      </p>
                    </div>
                  }
                  onAccept={onAcceptClick}
                  onDismiss={() => {}}
                  modalOpen={confirmMaxRedemptionModalOpen}
                  setModalOpen={setConfirmationMaxRedemptionModelOpen}
                />
              )}
            </>
          )}
          <Modal
            title={redemptionTypeProperties.modalTitle || 'Book tickets'}
            onClose={onModalClose}
            isOpen={modalOpen}
            onOpen={onModalOpen}
            noPadding
          >
            {redemptionTypeProperties.importantInfo && (
              <div
                className="SquadUpImportantInfo"
                dangerouslySetInnerHTML={{
                  __html: redemptionTypeProperties.importantInfo,
                }}
              />
            )}
            <div
              id="squadup-checkout"
              style={{ display: showPostEventBookingForm && 'none' }}
            />
            {showPostEventBookingForm && (
              <>
                {showConfirmation ? (
                  <Confirmation
                    confirmationText={
                      redemptionTypeProperties.modalConfirmationText
                    }
                  />
                ) : (
                  <div className="SquadUpPostEventBookingForm">
                    <FormBuilder
                      data={formAnswers}
                      errors={formErrors}
                      isLoading={isLoading}
                      onSubmit={handleSubmit}
                      questions={formData.questions}
                      submitButtonText={
                        redemptionTypeProperties.postEventBookingSubmitBtnText ||
                        'Complete Booking'
                      }
                    />
                  </div>
                )}
              </>
            )}
          </Modal>
        </>
      )}
      {showThankYou && (
        <>
          <ThankYouMessaging
            isEligibleSubscriber={isEligibleSubscriber}
            message={thankYouMessage}
            bordered
            borderCurved={!isUmbrellaView}
          />
          {addToCalendarEnabled && <AddToCalendar offerId={offerId} />}
        </>
      )}
    </>
  )
}

SquadUpRedemptionExperience.propTypes = {
  isSubscriber: PropTypes.bool,
  isEligibleSubscriber: PropTypes.bool,
  isRegistrant: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  addToCalendarEnabled: PropTypes.bool,
  offerId: PropTypes.string,
  userData: PropTypes.object,
  redemptionButtonText: PropTypes.string,
  redemptionTypeProperties: PropTypes.object,
  addToCalendarStartDate: PropTypes.instanceOf(Date),
  redemptionModalTitle: PropTypes.string,
  stripePublicKey: PropTypes.string,
  stripeJsVersion: PropTypes.string,
  formData: PropTypes.object,
  headline: PropTypes.string,
  redemptionLimit: PropTypes.number,
  consequentOffers: PropTypes.array,
  umbrellaChildrenOpenInNewPage: PropTypes.any,
  isUmbrella: PropTypes.bool,
  isUmbrellaView: PropTypes.bool,
  isSecondaryExperience: PropTypes.bool,
  userHasRedeemedPrimary: PropTypes.bool,
  userHasRedeemedSecondary: PropTypes.bool,
  userHasVipAccess: PropTypes.bool,
  restrictToVipOnly: PropTypes.bool,
}

export default SquadUpRedemptionExperience
