async function createCardUsage(params) {
  const { membershipId, restaurantId, offerId, deviceLocation } = params
  const result = await fetch('/api/gourmet-society/create-card-usage', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      membershipId,
      restaurantId,
      offerId,
      ...(deviceLocation && { deviceLocation }),
    }),
  })
  const data = await result.json()
  return data
}

export default createCardUsage
